import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {ShipmentBlueprintForOrderResponseI, ShipmentBlueprintI} from "../../../@types/shopwareApiTypes";

const prepareShipmentBlueprintForOrder = (
  data: ShipmentBlueprintForOrderResponseI
): ShipmentBlueprintI => {
  const preparedData = {
    ...data.shipmentBlueprint,
    parcels: data.shipmentBlueprint.parcels.map((parcel) => {
      return {
        ...parcel,
        weightOverwrite: {
          value: 1,
          unit: "kg",
        },
      };
    }),
  };
  return preparedData;
};
const getShipmentBlueprintForOrderQuery = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) =>
  builder.query<ShipmentBlueprintI, string>({
    providesTags: ["OrderAddress"],
    query: (orderId) => {
      const data = { orderId };

      return {
        url:
          "_action/pickware-shipping/shipment/create-shipment-blueprint-for-order",
        method: "POST",
        body: data,
      };
    },
    transformResponse: (data: ShipmentBlueprintForOrderResponseI) =>
      prepareShipmentBlueprintForOrder(data),
  });

export default getShipmentBlueprintForOrderQuery;
