import { NavigationContainer as DefaultNavigationContainer } from "@react-navigation/native";
import createDeepLinking from "@setup/deepLinking";
import AppLoading from "expo-app-loading";
import HomeScreen from "@screens/HomeScreen";
import DevNavigationScreen from "@screens/DevNavigationScreen";
import LoginScreen from "@screens/LoginScreen";
import IngredientsScreen from "@screens/IngredientsScreen";
import ProductLabelPrinterScreen from "@screens/ProductLabelPrinterScreen";
import ProductAssemblyScreen from "@screens/ProductAssemblyScreen";
import ShippingScreen from "@screens/ShippingScreen";
import ShippingLabelPrinterScreen from "@screens/ShippingLabelPrinterScreen";
import ProblemScreen from "@screens/ProblemScreen";
import NotFoundScreen from "@screens/NotFoundScreen";
import { createStackNavigator } from "@react-navigation/stack";
import { RootStackParamListT } from "../@types/appTypes";

const Stack = createStackNavigator<RootStackParamListT>();

const NavigationContainer = () => {
  return (
    <DefaultNavigationContainer
      linking={createDeepLinking()}
      fallback={<AppLoading />}
    >
      <Stack.Navigator
        screenOptions={{ headerShown: false }}
        initialRouteName="Home"
      >
        <Stack.Screen name="Home" component={HomeScreen} />
        <Stack.Screen name="DevNavigation" component={DevNavigationScreen} />
        <Stack.Screen name="Login" component={LoginScreen} />

        <Stack.Screen name="Ingredients" component={IngredientsScreen} />
        <Stack.Screen
          name="ProductLabelPrinter"
          component={ProductLabelPrinterScreen}
        />
        <Stack.Screen
          name="ProductAssembly"
          component={ProductAssemblyScreen}
        />
        <Stack.Screen name="Shipping" component={ShippingScreen} />
        <Stack.Screen
          name="ShippingLabelPrinter"
          component={ShippingLabelPrinterScreen}
        />
        <Stack.Screen name="Problem" component={ProblemScreen} />
        <Stack.Screen
          name="NotFound"
          component={NotFoundScreen}
          options={{ title: "Oops!" }}
        />
      </Stack.Navigator>
    </DefaultNavigationContainer>
  );
};
export default NavigationContainer;
