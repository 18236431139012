import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { useCallback, useEffect } from "react";
import { NavigationProp } from "@react-navigation/core/src/types";
import { isValidRouteForAppProgress } from "@util/screenInformation";
import app, { setAppProgressState } from "@redux/slices/app";
import { AppProgressState, RootStackParamListT } from "../@types/appTypes";

interface AppProgressNavigationI {
  children?: JSX.Element;
}
const AppProgressNavigation = ({ children }: AppProgressNavigationI) => {
  const appProgress = useAppSelector((state) => state.app.progress);
  const username = useAppSelector((state) => state.user.username);
  const navigation = useNavigation<NavigationProp<RootStackParamListT>>();
  const dispatch = useAppDispatch();
  const route = useRoute();

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //
  //   }, 1000 * 60 * 3);
  //   return () => clearInterval(interval);
  // }, []);

  useFocusEffect(
    useCallback(() => {
      if (!username) {
        dispatch(setAppProgressState({ progress: AppProgressState.LOGIN }));
        navigation.navigate("Login");
        return;
      }
      console.log("Check:", route.name, appProgress);
      if (route.name === "Home" || route.name === "Problem") {
        return;
      }
      if (route.name !== "Home" && appProgress === AppProgressState.START) {
        console.log("Navigate to Home");
        navigation.navigate("Home");
        return;
      }
      if (route.name !== "Problem" && appProgress === AppProgressState.ERROR) {
        console.log("Navigate to Error");
        navigation.navigate("Problem");
        return;
      }
      if (!isValidRouteForAppProgress(route.name, appProgress)) {
        dispatch(
          setAppProgressState({
            progress: AppProgressState.START,
            errorMessage: `${route.name} ist keine valide Route hier. [${appProgress}]`,
          })
        );
        navigation.navigate("Home");
      }
    }, [appProgress, dispatch, navigation, route.name, username])
  );
  return <>{children}</>;
};
AppProgressNavigation.defaultProps = {
  children: null,
};
export default AppProgressNavigation;
