import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

import { StackScreenProps } from "@react-navigation/stack";
import {
  useGetContainerQuery,
  useGetOrderLineItemQuery,
  useUpdateContainerMutation,
  useUpdateOrderLineItemStateMutation,
} from "@services/shopware6Api/adminApi";
import Layout from "@components/Layout";
import {
  resetAppProgressState,
  setAppProgressState,
  setErrorMessage,
  setInfoMessage,
} from "@redux/slices/app";
import { Button } from "react-native-paper";
import { resetBarcode } from "@redux/slices/scanner";
import { useCreatePrintJobMutation } from "@services/printApi/printApi";
import Constants from "expo-constants";
import AppProgressNavigation from "@navigation/AppProgressNavigation";
import { useFocusEffect } from "@react-navigation/native";
import {
  AppProgressState,
  ContainerState,
  OrderLineItemState,
  PrinterLabelType,
  RootStackParamListT,
} from "../@types/appTypes";

const SHOP_BASE_URL = Constants?.manifest?.extra?.SHOP_BASE_URL ?? "";

export default function ProductAssemblyScreen({
  navigation,
  route,
}: StackScreenProps<RootStackParamListT, "ProductAssembly">) {
  const { orderLineItemId, containerKey } = route.params;
  const dispatch = useAppDispatch();
  const appProgress = useAppSelector((state) => state.app.progress);
  const barcode = useAppSelector((state) => state.scanner.barcode);
  const [updateContainer] = useUpdateContainerMutation();
  const [updateOrderLineItemState] = useUpdateOrderLineItemStateMutation();
  const [createPrintJob] = useCreatePrintJobMutation();

  const { data: orderLineItem } = useGetOrderLineItemQuery(
    { orderLineItemId },
    { skip: !orderLineItemId }
  );
  const { data: container } = useGetContainerQuery(containerKey, {
    skip: !containerKey,
    refetchOnFocus: true,
  });
  const capsuleColor = useMemo(() => orderLineItem?.capsuleColor ?? "", [
    orderLineItem,
  ]);
  const orderLineItemPayload = useMemo(() => orderLineItem?.payload ?? null, [
    orderLineItem,
  ]);
  const containerId = useMemo(() => container?.id ?? "", [container]);

  useFocusEffect(
    useCallback(() => {
      switch (appProgress) {
        case AppProgressState.PRODUCT_ASSEMBLY_VALIDATE_CONTAINER_AND_ORDER_LINE_ITEM_STATE: {
          const validateContainer = async () => {
            if (!container?.state || !orderLineItem?.state) {
              return;
            }
            if (orderLineItem.state !== OrderLineItemState.MIXING) {
              await dispatch(
                setAppProgressState({
                  progress: AppProgressState.ERROR,
                  errorMessage:
                    "Die Bestellposition ist noch nicht bereit für die Weiterverarbeitung.",
                })
              );
              return;
            }
            if (container.state !== ContainerState.MIXING) {
              await dispatch(
                setAppProgressState({
                  progress: AppProgressState.ERROR,
                  errorMessage: `Der Container ${containerKey} ist noch nicht bereit für die Weiterverarbeitung.`,
                })
              );
              return;
            }
            await dispatch(resetBarcode());
            await dispatch(
              setAppProgressState({
                progress: AppProgressState.PRODUCT_ASSEMBLY_SCAN_CONTAINER,
              })
            );
          };

          validateContainer();

          break;
        }
        case AppProgressState.PRODUCT_ASSEMBLY_SCAN_CONTAINER: {
          const scanContainer = async () => {
            dispatch(
              setInfoMessage(
                `Bitte Container ${containerKey} verwenden und zum bestätigen scannen.`
              )
            );
            if (!barcode) {
              return;
            }

            if (barcode.toUpperCase() !== containerKey) {
              dispatch(
                setErrorMessage(
                  "Fehler! Code gehört nicht zum richtigen Container."
                )
              );
              return;
            }
            await dispatch(resetBarcode());
            await dispatch(
              setAppProgressState({
                progress: AppProgressState.PRODUCT_ASSEMBLY_PRINT_LABEL,
              })
            );
          };
          scanContainer();
          break;
        }
        case AppProgressState.PRODUCT_ASSEMBLY_PRINT_LABEL: {
          const printLabel = async () => {
            if (!orderLineItemId || barcode) {
              return;
            }
            const documentUri = `${SHOP_BASE_URL}/api/_action/wms/${orderLineItemId}/provision`;
            createPrintJob({
              documentUri,
              labelType: PrinterLabelType.QR_CODE,
            });
            await dispatch(
              setAppProgressState({
                progress: AppProgressState.PRODUCT_ASSEMBLY_SCAN_QR_CODE,
              })
            );
          };
          printLabel();
          break;
        }
        case AppProgressState.PRODUCT_ASSEMBLY_SCAN_QR_CODE: {
          const scanQRCode = async () => {
            dispatch(setInfoMessage("Bitte scannen Sie das Rückstellmuster."));
            if (!barcode || !orderLineItemId) {
              return;
            }
            if (barcode !== orderLineItemId) {
              dispatch(
                setErrorMessage(
                  "Fehler! Code gehört nicht zum richtigen Rückstellmuster."
                )
              );
              return;
            }
            await dispatch(resetBarcode());
            await dispatch(
              setAppProgressState({
                progress: AppProgressState.PRODUCT_ASSEMBLY_SCAN_CAPSULE,
              })
            );
          };
          scanQRCode();
          break;
        }
        case AppProgressState.PRODUCT_ASSEMBLY_SCAN_CAPSULE: {
          const scanCapsule = async () => {
            dispatch(setInfoMessage(`Kapselfarbcode scannen: ${capsuleColor}`));
            if (!barcode) {
              return;
            }
            if (barcode.toUpperCase() !== capsuleColor) {
              dispatch(setErrorMessage("Farbe nicht erkannt! Erneut Scannen."));
              return;
            }
            await dispatch(resetBarcode());
            await dispatch(
              setAppProgressState({
                progress:
                  AppProgressState.PRODUCT_ASSEMBLY_FILL_UP_CAPSULE_AND_CONTAINER,
              })
            );
          };
          scanCapsule();

          break;
        }
        case AppProgressState.PRODUCT_ASSEMBLY_FILL_UP_CAPSULE_AND_CONTAINER: {
          const fillCapsule = async () => {
            dispatch(
              setInfoMessage(
                "Bitte bestätigen Sie, dass der Container und die Kapsel befüllt wurden."
              )
            );
            if (!barcode) {
              return;
            }
            if (barcode.toUpperCase() !== "ACTIONPRODUCTASSEMBLY") {
              dispatch(setErrorMessage("Fehler! Erneut Scannen."));
              return;
            }
            await dispatch(resetBarcode());
            await dispatch(
              setAppProgressState({
                progress: AppProgressState.PRODUCT_ASSEMBLY_COMPLETE,
              })
            );
          };
          fillCapsule();
          break;
        }
        case AppProgressState.PRODUCT_ASSEMBLY_COMPLETE: {
          const completeAssembly = async () => {
            if (!containerId || !orderLineItemPayload) {
              dispatch(setErrorMessage("Es ist ein Fehler aufgetreten."));
              dispatch(
                setAppProgressState({ progress: AppProgressState.ERROR })
              );
              return;
            }
            await updateContainer({
              containerId,
              orderLineItemId,
              state: ContainerState.READY,
            });
            await updateOrderLineItemState({
              orderLineItemId,
              payloadState: OrderLineItemState.READY,
            });

            await dispatch(resetAppProgressState());
            navigation.navigate("Home");
          };
          completeAssembly();

          break;
        }
        default: {
          //
        }
      }
    }, [
      appProgress,
      barcode,
      capsuleColor,
      container?.state,
      containerId,
      containerKey,
      createPrintJob,
      dispatch,
      navigation,
      orderLineItem?.state,
      orderLineItemId,
      orderLineItemPayload,
      updateContainer,
      updateOrderLineItemState,
    ])
  );

  return (
    <AppProgressNavigation>
      <Layout hasInstructions devScreenName="product assembly">
        <>
          {appProgress === AppProgressState.PRODUCT_ASSEMBLY_SCAN_QR_CODE && (
            <Button
              mode="contained"
              onPress={() => {
                if (!orderLineItemId) {
                  return;
                }
                const documentUri = `${SHOP_BASE_URL}/api/_action/wms/${orderLineItemId}/provision`;
                createPrintJob({
                  documentUri,
                  labelType: PrinterLabelType.QR_CODE,
                });
              }}
            >
              Erneut drucken?
            </Button>
          )}
        </>
      </Layout>
    </AppProgressNavigation>
  );
}
