import { ReactElement, useEffect, useState } from "react";
import { Asset } from "expo-asset";
import * as Font from "expo-font";
import AppLoading from "expo-app-loading";

export type FontSource = Parameters<typeof Font.loadAsync>[0];
const usePromiseAll = (
  promises: Promise<void | void[] | Asset[]>[],
  cb: () => void
) =>
  useEffect(() => {
    (async () => {
      await Promise.all(promises);
      cb();
    })();
  });

const useLoadAssets = (assets: number[], fonts: FontSource): boolean => {
  const [ready, setReady] = useState(false);

  usePromiseAll(
    [Font.loadAsync(fonts), ...assets.map((asset) => Asset.loadAsync(asset))],
    () => setReady(true)
  );
  return ready;
};

interface AssetProviderI {
  fonts?: FontSource;
  assets?: number[];
  children: ReactElement | ReactElement[];
}

const AssetProvider = ({ assets, fonts, children }: AssetProviderI) => {
  const ready = useLoadAssets(assets || [], fonts || {});

  if (!ready) {
    return <AppLoading />;
  }
  return <>{children}</>;
};
AssetProvider.defaultProps = {
  fonts: null,
  assets: null,
};
export default AssetProvider;
