import Layout from "@components/Layout";
import { StackScreenProps } from "@react-navigation/stack";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

import Constants from "expo-constants";
import { resetAppProgressState } from "@redux/slices/app";
import Dialog from "@components/Dialog";
import { useCreatePrintJobMutation } from "@services/printApi/printApi";
import AppProgressNavigation from "@navigation/AppProgressNavigation";
import {
  AppProgressState,
  PrinterLabelType,
  RootStackParamListT,
} from "../@types/appTypes";

const PRODUCT_LABEL_PDF_BASE_URL =
  Constants?.manifest?.extra?.PRODUCT_LABEL_PDF_BASE_URL ?? "";

export default function ProductLabelPrinterScreen({
  route,
  navigation,
}: StackScreenProps<RootStackParamListT, "ProductLabelPrinter">) {
  const { orderLineItemId } = route.params;
  const dispatch = useAppDispatch();
  const appProgress = useAppSelector((state) => state.app.progress);
  const [createPrintJob] = useCreatePrintJobMutation();

  return (
    <AppProgressNavigation>
      <Layout hasInstructions devScreenName="product label printer">
        <>
          {AppProgressState.PRODUCT_PRINT_PRODUCT_LABEL_DIALOG ===
            appProgress && (
            <Dialog
              title="Produktetikett drucken?"
              text={
                "Falls das Etikett bereits gedruckt wurde klicken Sie " +
                'auf "Ohne drucken fortfahren" um diesen Schritt zu überspringen.'
              }
              continueText="Drucken"
              declineText="Ohne drucken fortfahren"
              onContinue={async () => {
                const documentUri = `${PRODUCT_LABEL_PDF_BASE_URL}${orderLineItemId}`;
                createPrintJob({
                  documentUri,
                  labelType: PrinterLabelType.PRODUCT,
                });
                await dispatch(resetAppProgressState());
                navigation.navigate("Home");
              }}
              onDecline={async () => {
                await dispatch(resetAppProgressState());
                navigation.navigate("Home");
              }}
            />
          )}
        </>
      </Layout>
    </AppProgressNavigation>
  );
}
