import { StackScreenProps } from "@react-navigation/stack";
import AppProgressNavigation from "@navigation/AppProgressNavigation";
import Layout from "@components/Layout";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  clearErrorMessage,
  resetAppProgressState,
  setErrorMessage,
  setInfoMessage,
} from "@redux/slices/app";
import { resetUsername, updateUsername } from "@redux/slices/user";
import { resetTokenData, signInAsync } from "@redux/slices/auth";
import { resetBarcode } from "@redux/slices/scanner";
import { forEach } from "lodash";
import { isValidUsernameFormat } from "@util/barcodeValidators";
import { useFocusEffect } from "@react-navigation/native";
import {
  AppProgressState,
  RootStackParamListT,
  ValidationErrorsI,
} from "../@types/appTypes";

export default function LoginScreen({
  navigation,
}: StackScreenProps<RootStackParamListT, "Login">) {
  const appProgress = useAppSelector((state) => state.app.progress);
  const barcode = useAppSelector((state) => state.scanner.barcode);
  const dispatch = useAppDispatch();
  useFocusEffect(
    useCallback(() => {
      if (AppProgressState.LOGIN !== appProgress) {
        return;
      }
      dispatch(setInfoMessage("Persönlichen Barcode zum Anmelden scannen."));
      if (!barcode) {
        return;
      }
      if (!isValidUsernameFormat(barcode)) {
        dispatch(setErrorMessage(`"${barcode}" ist kein gültiger Nutzername!`));
        return;
      }
      (async () => {
        const username = dispatch(updateUsername(barcode)).payload;
        dispatch(resetBarcode());
        const signInResult = await dispatch(signInAsync(username));

        if (signInResult.type === "auth/signInAsync/pending") {
          console.log("Reset user in Login Screen");
          dispatch(resetUsername());
          dispatch(resetTokenData());
          dispatch(clearErrorMessage());
          return;
        }
        if (signInResult.type === "auth/signInAsync/rejected") {
          const validationErrors = signInResult.payload as ValidationErrorsI;
          const { errors: signInErrors } = validationErrors;
          let errorMessage = "";
          forEach(signInErrors, (signInError) => {
            errorMessage += `${signInError.title}\n\r`;
          });
          dispatch(setErrorMessage(errorMessage));
          return;
        }
        dispatch(resetAppProgressState());
        navigation.navigate("Home");
      })();
    }, [appProgress, barcode, dispatch, navigation])
  );
  return (
    <AppProgressNavigation>
      <Layout hasInstructions />
    </AppProgressNavigation>
  );
}
