import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import Constants from "expo-constants";

const PRINT_API_URL = Constants?.manifest?.extra?.PRINT_API_URL ?? "";
const PRINT_API_KEY = Constants?.manifest?.extra?.PRINT_API_KEY ?? "";

const baseQuery = fetchBaseQuery({
  baseUrl: `${PRINT_API_URL}/`,
  prepareHeaders: (headers) => {
    headers.set("Authorization", `Basic ${PRINT_API_KEY}`);
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    headers.set("X-Account", "");
    return headers;
  },
});

export default baseQuery;
