import { decode, encode } from "base-64";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Constants from "expo-constants";

if (!global.btoa) {
  global.btoa = encode;
}

if (!global.atob) {
  global.atob = decode;
}

const SHOP_BASE_URL = Constants?.manifest?.extra?.SHOP_BASE_URL ?? "";
const CLIENT_SECRET = Constants?.manifest?.extra?.SECRET_ACCESS_KEY ?? "";

const authRequestConfig = {
  auth: {
    username: "administration",
  },
} as AxiosRequestConfig;

const authenticateAsync = async <T extends unknown>(
  username: string
): Promise<AxiosResponse<T>> => {
  const data = {
    grant_type: "password",
    scope: "write",
    username,
    password: CLIENT_SECRET,
  };
  return axios.post(
    `${SHOP_BASE_URL}/api/oauth/token`,
    data,
    authRequestConfig
  );
};

const refreshAuthenticateAsync = async <T extends unknown>(
  refresh_token: string
): Promise<AxiosResponse<T>> => {
  const data = {
    grant_type: "refresh_token",
    refresh_token,
  };
  return axios.post(
    `${SHOP_BASE_URL}/api/oauth/token`,
    data,
    authRequestConfig
  );
};

export { authenticateAsync, refreshAuthenticateAsync };
