import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "@services/printApi/baseQuery";
import createPrintJobMutation from "@services/printApi/endpoints/createPrintJobMutation";
import checkWeight from "@services/printApi/endpoints/checkWeight";

export const printApi = createApi({
  reducerPath: "printApi",
  baseQuery,
  keepUnusedDataFor: 5,
  endpoints: (builder) => ({
    createPrintJob: createPrintJobMutation(builder),
    checkWeight: checkWeight(builder),
  }),
});

export const { useCreatePrintJobMutation, useLazyCheckWeightQuery } = printApi;
