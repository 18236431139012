import { cloneDeep } from "lodash";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { OrderLineItemResponseI } from "../../../../@types/shopwareApiTypes";
import { IngredientI, OrderLineItemI } from "../../../../@types/appTypes";

import requestData from "./requestData.json";

const ingredientAdapter = createEntityAdapter<IngredientI>({
  selectId: (ingredient) => ingredient.productNumber,
  sortComparer: (a, b) => {
    if (a.position === b.position) return 0;
    return a.position < b.position ? -1 : 1;
  },
});

const prepareOrderLineItemData = (
  data: OrderLineItemResponseI
): OrderLineItemI => {
  const { order, position } = data;
  const { orderCustomer, orderNumber, customFields } = order;
  const [container] = data.extensions.container;
  const batchNumber = `${orderNumber}-${position}`;
  const ingredients = data.children
    .filter((ingredient) => !ingredient.payload.isBase)
    .map((ingredient) => {
      return {
        id: ingredient.id,
        position: ingredient.position,
        box: ingredient.payload.box,
        node: ingredient.payload.node,
        productNumber: ingredient.payload.productNumber,
        label: ingredient.label,
        quantity: ingredient.quantity,
        state: ingredient.payload.state,
        payload: ingredient.payload,
        categoryColor: ingredient.payload.categoryColor,
        needsWeighing: ingredient.payload.needsWeighing,
      };
    });
  return {
    id: data.id,
    orderId: data.orderId,
    orderState: data.order.stateMachineState.technicalName,
    isNaturopath: customFields?.dvsn_seller_order_status ?? false,
    batchNumber,
    label: data.label,
    payload: data.payload,
    state: data.payload.state,
    container,
    capsuleColor: data.payload.capsuleColor,
    controlWeight: data.payload.calcWeight,
    fillerWeight: data.payload.fillerWeight,
    customer: {
      id: orderCustomer.id,
      lastName: orderCustomer.lastName,
    },
    ingredients: ingredientAdapter.setAll(
      ingredientAdapter.getInitialState(),
      ingredients
    ),
  };
};
const getOrderLineItemQuery = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) => {
  return builder.query<OrderLineItemI, { orderLineItemId: null | string }>({
    providesTags: (_result, _error, _) => ["OrderLineItem"],
    query: ({ orderLineItemId }) => {
      const data: any = cloneDeep(requestData);
      if (orderLineItemId) {
        data.filter.push({
          type: "equals",
          field: "id",
          value: orderLineItemId,
        });
      } else {
        data.filter.push({
          type: "equals",
          field: "parentId",
          value: null,
        });
        data.filter.push({
          type: "equals",
          field: "payload.state",
          value: 0,
        });
      }
      return {
        url: "search/order-line-item",
        method: "POST",
        body: data,
      };
    },
    transformResponse: ({ data }: { data: OrderLineItemResponseI[] }) => {
      if (!data?.length) {
        return null;
      }
      return prepareOrderLineItemData(data[0]);
    },
  });
};

export default getOrderLineItemQuery;
