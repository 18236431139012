import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import Constants from "expo-constants";
import {
  PrintNodeScaleResponseI,
  ScaleType,
} from "../../../../@types/appTypes";

const COMPUTER_ID_CONTAINER_SCALE =
  Constants?.manifest?.extra?.COMPUTER_ID_CONTAINER_SCALE ?? "";
const COMPUTER_ID_VITAMINS_SCALE =
  Constants?.manifest?.extra?.COMPUTER_ID_VITAMINS_SCALE ?? "";
const DEVICE_CONTAINER_SCALE =
  Constants?.manifest?.extra?.DEVICE_CONTAINER_SCALE ?? "";
const DEVICE_VITAMINS_SCALE =
  Constants?.manifest?.extra?.DEVICE_VITAMINS_SCALE ?? "";

const checkWeight = (builder: EndpointBuilder<BaseQueryFn, string, string>) => {
  return builder.query<string, { scaleType: ScaleType }>({
    query: ({ scaleType }) => {
      const data: any = {};
      switch (scaleType) {
        case ScaleType.CONTAINER: {
          data.comp = COMPUTER_ID_CONTAINER_SCALE;
          data.device = DEVICE_CONTAINER_SCALE;
          break;
        }
        case ScaleType.VITAMINS: {
          data.comp = COMPUTER_ID_VITAMINS_SCALE;
          data.device = DEVICE_VITAMINS_SCALE;
          break;
        }
        default: {
          break;
        }
      }
      return {
        url: `/computer/${data.comp}/scales/${data.device}/0`,
        method: "GET",
      };
    },
    transformResponse: ({ data }: { data: PrintNodeScaleResponseI }) => {
      if (!data?.ageOfData || data?.ageOfData > 500) {
        return null;
      }
      if (!data?.measurement?.g) {
        return null;
      }

      return data.measurement.g / 1000;
    },
  });
};

export default checkWeight;
