import includes from "lodash/includes";

import { AppProgressState } from "../@types/appTypes";

interface IsValidRouteForAppProgressI {
  (routeName: string, appProgress: AppProgressState): boolean;
}

const isValidRouteForAppProgress: IsValidRouteForAppProgressI = (
  routeName,
  appProgress
) => {
  switch (routeName) {
    case "Ingredients": {
      return includes(
        [
          AppProgressState.INGREDIENT_SCAN_CHECK,
          AppProgressState.INGREDIENT_SCAN_CLOSE,
          AppProgressState.INGREDIENT_SCAN_CONTROL_WEIGHING,
          AppProgressState.INGREDIENT_SCAN_MIXING_HATCH,
        ],
        appProgress
      );
    }
    case "ProductLabelPrinter": {
      return includes(
        [AppProgressState.PRODUCT_PRINT_PRODUCT_LABEL_DIALOG],
        appProgress
      );
    }
    case "ProductAssembly": {
      return includes(
        [
          AppProgressState.PRODUCT_ASSEMBLY_VALIDATE_CONTAINER_AND_ORDER_LINE_ITEM_STATE,
          AppProgressState.PRODUCT_ASSEMBLY_SCAN_CONTAINER,
          AppProgressState.PRODUCT_ASSEMBLY_PRINT_LABEL,
          AppProgressState.PRODUCT_ASSEMBLY_SCAN_QR_CODE,
          AppProgressState.PRODUCT_ASSEMBLY_SCAN_CAPSULE,
          AppProgressState.PRODUCT_ASSEMBLY_FILL_UP_CAPSULE_AND_CONTAINER,
          AppProgressState.PRODUCT_ASSEMBLY_COMPLETE,
        ],
        appProgress
      );
    }
    case "Shipping": {
      return includes(
        [
          AppProgressState.SHIPPING_VALIDATE_ORDER_STATE,
          AppProgressState.SHIPPING_SCAN_ORDER_LINE_ITEMS,
          AppProgressState.SHIPPING_CREATE_SHIPMENT_LABEL,
        ],
        appProgress
      );
    }
    case "ShippingLabelPrinter": {
      return includes(
        [
          AppProgressState.SHIPPING_CREATE_SHIPMENT_LABEL,
          AppProgressState.SHIPPING_PRINT_SHIPMENT_LABEL,
          AppProgressState.SHIPPING_SCAN_SHIPMENT_LABEL,
          AppProgressState.SHIPPING_ORDER_COMPLETED,
        ],
        appProgress
      );
    }
    case "Problem": {
      return includes([AppProgressState.ERROR], appProgress);
    }
    case "Home":
    default: {
      return true;
    }
  }
};
interface GetScreenNameByAppProgressI {
  (appProgress: AppProgressState, routeName: string): string;
}
const getScreenNameByAppProgress: GetScreenNameByAppProgressI = (
  appProgress
) => {
  switch (appProgress) {
    case AppProgressState.INGREDIENT_SCAN_CHECK:
    case AppProgressState.INGREDIENT_SCAN_CLOSE:
    case AppProgressState.INGREDIENT_SCAN_CONTROL_WEIGHING:
    case AppProgressState.INGREDIENT_SCAN_MIXING_HATCH: {
      return "Inhaltsstoffe";
    }
    case AppProgressState.PRODUCT_PRINT_PRODUCT_LABEL_DIALOG: {
      return "Produktetikett drucken";
    }
    case AppProgressState.PRODUCT_ASSEMBLY_VALIDATE_CONTAINER_AND_ORDER_LINE_ITEM_STATE:
    case AppProgressState.PRODUCT_ASSEMBLY_SCAN_CONTAINER:
    case AppProgressState.PRODUCT_ASSEMBLY_PRINT_LABEL:
    case AppProgressState.PRODUCT_ASSEMBLY_SCAN_QR_CODE:
    case AppProgressState.PRODUCT_ASSEMBLY_SCAN_CAPSULE:
    case AppProgressState.PRODUCT_ASSEMBLY_FILL_UP_CAPSULE_AND_CONTAINER:
    case AppProgressState.PRODUCT_ASSEMBLY_COMPLETE: {
      return "Fertigung";
    }
    case AppProgressState.SHIPPING_VALIDATE_ORDER_STATE:
    case AppProgressState.SHIPPING_SCAN_ORDER_LINE_ITEMS:
    case AppProgressState.SHIPPING_CREATE_SHIPMENT_LABEL:
    case AppProgressState.SHIPPING_PRINT_SHIPMENT_LABEL:
    case AppProgressState.SHIPPING_SCAN_SHIPMENT_LABEL:
    case AppProgressState.SHIPPING_ORDER_COMPLETED: {
      return "Versand";
    }
    case AppProgressState.ERROR: {
      return "Problem";
    }
    case AppProgressState.LOGIN: {
      return "Anmeldung";
    }
    case AppProgressState.START:
    default: {
      return "WUNSCHKAPSEL";
    }
  }
};

export { isValidRouteForAppProgress, getScreenNameByAppProgress };
