import { EntityState } from "@reduxjs/toolkit";
import {
  ContainerOrderLineItemExtensionI,
  ContainerOrderLineItemOrderI,
  ContainerOrderLineItemPayloadI,
  IngredientOrderLineItemI,
} from "./shopwareApiTypes";

export enum AppProgressState {
  START,
  INGREDIENT_SCAN_CHECK,
  INGREDIENT_SCAN_CLOSE,
  INGREDIENT_SCAN_CONTROL_WEIGHING,
  INGREDIENT_SCAN_MIXING_HATCH,
  PRODUCT_PRINT_PRODUCT_LABEL_DIALOG,
  PRODUCT_ASSEMBLY_VALIDATE_CONTAINER_AND_ORDER_LINE_ITEM_STATE,
  PRODUCT_ASSEMBLY_SCAN_CONTAINER,
  PRODUCT_ASSEMBLY_PRINT_LABEL,
  PRODUCT_ASSEMBLY_SCAN_QR_CODE,
  PRODUCT_ASSEMBLY_SCAN_CAPSULE,
  PRODUCT_ASSEMBLY_FILL_UP_CAPSULE_AND_CONTAINER,
  PRODUCT_ASSEMBLY_COMPLETE,
  SHIPPING_VALIDATE_ORDER_STATE,
  SHIPPING_SCAN_ORDER_LINE_ITEMS,
  SHIPPING_CREATE_SHIPMENT_LABEL,
  SHIPPING_PRINT_SHIPMENT_LABEL,
  SHIPPING_SCAN_SHIPMENT_LABEL,
  SHIPPING_ORDER_COMPLETED,
  ERROR = -1,
  LOGIN = -2,
  DEV = -99,
}

export enum Loading {
  "idle",
  "pending",
  "succeeded",
  "failed",
}

export enum OrderStatus {
  REOPEN = "reopen",
  OPEN = "open",
  IN_PROGRESS = "process",
  COMPLETED = "complete",
}

export enum OrderStatusActionName {
  REOPEN = "reopen",
  OPEN = "open",
  IN_PROGRESS = "in_progress",
  COMPLETED = "complete",
}

export enum OrderDeliveryStatus {
  OPEN = "open",
  IN_PROGRESS = "ship_partially",
  COMPLETED = "ship",
}

export enum OrderLineItemState {
  OPEN,
  PROCESS,
  MIXING,
  READY,
  SHIPPING,
  PROBLEM = -1,
}

export enum ContainerState {
  OPEN,
  IN_USE,
  MIXING,
  READY,
  PROBLEM = -1,
}
export enum PrinterLabelType {
  PRODUCT,
  QR_CODE,
  SHIPMENT,
}
export enum ScaleType {
  CONTAINER,
  VITAMINS,
}

export type RootStackParamListT = {
  DevNavigation: undefined;
  Home: undefined;
  Ingredients: { orderLineItemId: string; containerKey: string };
  Login: undefined;
  ProductAssembly: { orderLineItemId: string; containerKey: string };
  ProductLabelPrinter: { orderLineItemId: string };
  NotFound: undefined;
  Problem: undefined;
  Shipping: { orderLineItemId: string };
  ShippingLabelPrinter: { orderId: string; orderLineItemId: string };
  ShippingAddress: { orderId: string; orderLineItemId: string };
};

export interface OrderLineItemI {
  id: string;
  orderId: string;
  orderState: OrderStatusActionName;
  isNaturopath: boolean;
  batchNumber: string;
  label: string;
  payload: object; // TODO fix
  state: OrderLineItemState;
  container: ContainerI;
  capsuleColor: string;
  controlWeight: number;
  fillerWeight: number;
  customer: CustomerI;
  ingredients: EntityState<IngredientI>;
}

export interface IngredientI {
  id: string;
  position: string;
  productNumber: string;
  label: string;
  quantity: number;
  payload: object; // TODO fix
  state: OrderLineItemState;
  categoryColor: string | undefined;
  needsWeighing: boolean | undefined;
  box: IngredientBoxI;
  node: string;
}

export interface IngredientBoxI {
  box: number;
  node: string;
}

export interface ContainerI {
  id: string;
  key: string;
  state: ContainerState;
  isUsed: boolean;
  orderLineItemId: string;
}

export interface ShippingOrderI {
  orderNumber: any;
  orderId: string;
  state: OrderStatusActionName;
  isNaturopath: boolean;
  orderDeliveryId: string;
  shippingAddress: ShippingOrderShippingAddressI;
  orderLineItems: EntityState<ShippingOrderLineItemI>;
}
export interface ShippingOrderLineItemI {
  id: string;
  label: string;
  quantity: number;
  state: OrderLineItemState;
  productNumber: string;
  type: string;
  batchNumber: string;
}
export interface ShippingOrderShippingAddressI {
  id: string;
  firstName: string;
  lastName: string;
  street: string;
  zipcode: string;
  city: string;
  department: string;
  additionalAddressLine1: string;
  additionalAddressLine2: string;
  country: string;
}

export interface CustomerI {
  id: string;
  lastName: string;
}

export interface ValidationErrorsI {
  errors: ValidationErrorI[];
}
export interface ValidationErrorI {
  code: string;
  status: string;
  title: string;
  detail: any;
}
export interface ShipmentDocumentI {
  shipmentId: string;
  shippingOrderAddressId: string;
  orderDeliveryId: string;
  documentId: string;
  deepLinkCode: string;
  trackingCode: string;
}
export type ParamList = Record<string, ParameterI | undefined>;
export interface ParameterI {
  orderLineItemId?: string;
  containerKey?: string;
}

export interface PrintNodeScaleMeasurementResponseI {
  g: number;
}

export interface PrintNodeScaleResponseI {
  id: string;
  deviceName: string;
  measurement: PrintNodeScaleMeasurementResponseI;
  ageOfData: number;
}
