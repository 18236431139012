import { ReactElement } from "react";
import TopLevelErrorBoundary from "./TopLevelErrorBoundary";

interface CombinedErrorBoundariesI {
  children: ReactElement | ReactElement[];
}

const CombinedErrorBoundaries = ({ children }: CombinedErrorBoundariesI) => {
  return <TopLevelErrorBoundary>{children}</TopLevelErrorBoundary>;
};

export default CombinedErrorBoundaries;
