import Layout from "@components/Layout";
import { StackScreenProps } from "@react-navigation/stack";
import Dialog from "@components/Dialog";
import { resetAppProgressState } from "@redux/slices/app";
import { useAppDispatch } from "@redux/hooks";
import { resetBarcode } from "@redux/slices/scanner";
import AppProgressNavigation from "@navigation/AppProgressNavigation";
import { RootStackParamListT } from "../@types/appTypes";

export default function ProblemScreen({
  navigation,
}: StackScreenProps<RootStackParamListT, "Problem">) {
  const dispatch = useAppDispatch();

  return (
    <AppProgressNavigation>
      <Layout hasInstructions devScreenName="problem">
        <Dialog
          title="Problem behoben"
          text="Du wirst nach Home weitergeleitet."
          onContinue={async () => {
            await dispatch(resetBarcode());
            await dispatch(resetAppProgressState());
            navigation.navigate("Home");
          }}
        />
      </Layout>
    </AppProgressNavigation>
  );
}
