import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const updateContainerMutation = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) =>
  builder.mutation<any, any>({
    query: ({ containerId, orderLineItemId, state }) => {
      const data = { orderLineItemId, state };
      return {
        url: `container/${containerId}`,
        method: "PATCH",
        body: data,
      };
    },
    invalidatesTags: ["Container", "OrderLineItem"],
  });

export default updateContainerMutation;
