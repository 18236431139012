import { StyleSheet, View } from "react-native";
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  withSpring,
} from "react-native-reanimated";
import { Caption, Subheading, Text } from "react-native-paper";
import { useAppSelector } from "@redux/hooks";
import { AppProgressState, IngredientI } from "../@types/appTypes";

export const CARD_HEIGHT = 200;
export const CARD_WIDTH = 300;
export const SCALE_FACTOR = -0.1;

const styles = StyleSheet.create({
  cardWrapper: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "#efefef",
    opacity: 1,
    transform: [{ translateY: 0 }, { scale: 1 }],
  },
  ingredientCategoryIndicator: {
    height: 20,
  },
  ingredientInfoWrapper: {
    padding: 15,
    flex: 1,
    display: "flex",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  ingredientLabel: {
    padding: 10,
    fontSize: 20,
    color: "#666",
  },
  ingredientText: {
    padding: 10,
    fontSize: 25,
    color: "#222",
  },
  ingredientBottomText: {
    padding: 10,
    fontSize: 25,
    color: "#222",
  },
  ingredientStepText: {
    padding: 10,
    color: "#222",
  },
});

export interface IngredientCardInterface {
  index: number;
  currentStepNr: Animated.SharedValue<number>;
  totalStepsNr: number;
  ingredient: IngredientI;
}

export default function IngredientCard({
  index,
  currentStepNr,
  totalStepsNr,
  ingredient,
}: IngredientCardInterface) {
  const appProgress = useAppSelector((state) => state.app.progress);
  const ingredientQuantityText = `${ingredient.quantity / 1000}g`;
  const ingredientBox = ingredient?.box?.box ? ingredient.box.box : "";
  const stepProgressText = `${index + 1}/${totalStepsNr}`;

  const relativeIndex = useDerivedValue(() => {
    return index - currentStepNr.value;
  }, [currentStepNr]);

  const animatedOffsetStyle = useAnimatedStyle(() => {
    const scale = SCALE_FACTOR * relativeIndex.value + 1;
    const offset =
      -CARD_HEIGHT / 2 +
      (CARD_HEIGHT / 2) * scale -
      10 * relativeIndex.value * scale;

    return {
      transform: [
        {
          translateY: withSpring(offset, {
            damping: 20,
            stiffness: 90,
          }),
        },
        {
          scale: withSpring(scale, {
            damping: 20,
            stiffness: 90,
          }),
        },
      ],
      opacity: withSpring(
        relativeIndex.value < 0 || relativeIndex.value > 3 ? 0 : 1,
        {
          damping: 20,
          stiffness: 90,
        }
      ),
    };
  }, [currentStepNr, relativeIndex]);

  return (
    <Animated.View
      style={[styles.cardWrapper, animatedOffsetStyle, { zIndex: -index }]}
    >
      <View
        style={[
          styles.ingredientCategoryIndicator,
          {
            backgroundColor: ingredient.categoryColor
              ? ingredient.categoryColor
              : "#dfdfdf",
          },
        ]}
      />
      <View style={styles.ingredientInfoWrapper}>
        <Subheading style={styles.ingredientLabel}>
          {ingredient.label}
        </Subheading>
        <View style={styles.infoContainer}>
          {/* TODO https://github.com/callstack/react-native-paper/issues/1971 */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */}
          {/* @ts-ignore */}
          <Text style={styles.ingredientText}>{ingredientBox}</Text>
          <>
            {appProgress === AppProgressState.INGREDIENT_SCAN_CLOSE && (
              // TODO https://github.com/callstack/react-native-paper/issues/1971
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              <Text style={styles.ingredientBottomText}>
                {ingredientQuantityText}
              </Text>
            )}
          </>
        </View>
        <Caption style={styles.ingredientStepText}>{stepProgressText}</Caption>
      </View>
    </Animated.View>
  );
}
