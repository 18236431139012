import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ScannerState {
  barcode: string;
}

const initialState: ScannerState = {
  barcode: "",
};

export const scannerSlice = createSlice({
  name: "scanner",
  initialState,
  reducers: {
    updateBarcode: (state, action: PayloadAction<string>) => {
      state.barcode = action.payload;
    },
    resetBarcode: (state) => {
      state.barcode = initialState.barcode;
    },
  },
});

export const { updateBarcode, resetBarcode } = scannerSlice.actions;

export default scannerSlice.reducer;
