import { useEffect, useRef } from "react";
import { View } from "react-native";
import { useAppDispatch } from "@redux/hooks";
import { updateBarcode } from "@redux/slices/scanner";
import throttle from "lodash/throttle";

export default function Scanner() {
  const dispatch = useAppDispatch();
  const lastKeyPress = useRef<number | null>(null);
  const scannedText = useRef("");

  useEffect(() => {
    const sendBarcode = throttle((barcode: string) => {
      let scannedBarcode = barcode;
      if (barcode.startsWith("https")) {
        scannedBarcode = barcode.substr(
          barcode.lastIndexOf("/") + 1,
          barcode.length
        );
      }
      dispatch(updateBarcode(scannedBarcode));
    }, 500);

    const setKeyPressed = (e: { key: string }) => {
      let { key } = e;
      const currentTime = new Date().getTime();
      const throttled =
        lastKeyPress.current && currentTime - lastKeyPress.current > 200;

      if (["Shift"].includes(e.key)) {
        return;
      }
      if (key === "ß") key = "-";
      if (key === "?") key = "_";

      if (key === "Enter") {
        sendBarcode(scannedText.current);
        scannedText.current = "";
        return;
      }

      if (throttled) {
        scannedText.current = key;
      } else {
        scannedText.current += key;
      }
      lastKeyPress.current = currentTime;
    };
    window.addEventListener("keyup", setKeyPressed);

    const pasteEvent = (e: any) => {
      const text = (e.clipboardData || window.clipboardData).getData("text");

      dispatch(updateBarcode(text));
      // stop default paste
      e.preventDefault();
    };

    document.addEventListener("paste", pasteEvent);

    // cleanup this component
    return () => {
      window.removeEventListener("keyup", setKeyPressed);
      document.removeEventListener("paste", pasteEvent);
    };
  }, [dispatch, scannedText]);

  return <View />;
}
