import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import {
  ShippingOrderLineItemResponseI,
  ShippingOrderI as ShopwareShippingOrderI,
} from "../../../../@types/shopwareApiTypes";
import {
  ShippingOrderI,
  ShippingOrderLineItemI,
} from "../../../../@types/appTypes";
import requestData from "./requestData.json";
import readyToShipRequestData from "./readyToShipRequestData.json";

const shippingOrderLineItemAdapter = createEntityAdapter<
  ShippingOrderLineItemI
>({
  sortComparer: (a, b) => {
    if (a.batchNumber === b.batchNumber) return 0;
    return a.batchNumber < b.batchNumber ? -1 : 1;
  },
});

const prepareOrderData = (order: ShopwareShippingOrderI): ShippingOrderI => {
  const {
    id: orderId,
    deliveries: [{ id, shippingOrderAddress }],
    stateMachineState: { technicalName },
    lineItems,
    orderNumber,
    customFields,
  } = order;

  return {
    orderId,
    orderNumber,
    state: technicalName,
    isNaturopath: customFields?.dvsn_seller_order_status ?? false,
    orderDeliveryId: id,
    shippingAddress: shippingOrderAddress,
    orderLineItems: shippingOrderLineItemAdapter.setAll(
      shippingOrderLineItemAdapter.getInitialState(),
      lineItems.map((lineItem) => {
        return {
          id: lineItem.id,
          label: lineItem.label,
          productNumber: lineItem.payload.productNumber,
          type: lineItem.type,
          quantity: lineItem.quantity,
          state: lineItem.payload.state,
          batchNumber: `${orderNumber}-${lineItem.position}`,
        };
      })
    ),
  };
};

const prepareShippingOrderData = (
  data: ShippingOrderLineItemResponseI
): ShippingOrderI => {
  return prepareOrderData(data.order);
};

export const getShippingOrderQuery = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) => {
  return builder.query<ShippingOrderI, void | string>({
    providesTags: (_result, _error, _) => ["Container", "OrderLineItem"],
    query: (orderLineItemId) => {
      const data: any = cloneDeep(requestData);
      if (orderLineItemId) {
        data.filter.push({
          type: "equals",
          field: "id",
          value: orderLineItemId,
        });
      }
      data.limit = 1;

      return {
        url: "search/order-line-item",
        method: "POST",
        body: data,
      };
    },
    transformResponse: ({
      data,
    }: {
      data: ShippingOrderLineItemResponseI[];
    }) => {
      if (!data?.length) {
        return null;
      }
      return prepareShippingOrderData(data[0]);
    },
  });
};

export const getReadyShippingOrderQuery = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) => {
  return builder.query<ShippingOrderI[], void | string>({
    providesTags: (_result, _error, _) => ["Container", "OrderLineItem"],
    query: () => {
      const data: any = cloneDeep(readyToShipRequestData);

      return {
        url: "search/order",
        method: "POST",
        body: data,
      };
    },
    transformResponse: ({ data }: { data: ShopwareShippingOrderI[] }) => {
      if (!data?.length) {
        return null;
      }
      return data.map(prepareOrderData);
    },
  });
};
