export const isValidContainerFormat = (barcode: string): boolean =>
  barcode.toUpperCase().startsWith("DOSE-") && barcode.length === 9;

export const isValidUuidFormat = (barcode: string): boolean => {
  const regex = new RegExp("^[0-9a-fA-F]{32}$");

  return regex.test(barcode);
};

export const isValidUsernameFormat = (barcode: string): boolean => {
  const regex = new RegExp("^.*_\\d+$");
  return regex.test(barcode.toUpperCase());
};
