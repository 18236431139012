import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { OrderStatus } from "../../../@types/appTypes";

const updateOrderStateMutation = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) =>
  builder.mutation<any, { orderId: string; newOrderState: OrderStatus }>({
    query: ({ orderId, newOrderState }) => {
      return {
        url: `_action/order/${orderId}/state/${newOrderState}`,
        method: "POST",
      };
    },
    invalidatesTags: ["Order"],
  });

export default updateOrderStateMutation;
