import Constants from "expo-constants";
import * as ExpoLinking from "expo-linking";

const DEEP_LINK_PREFIX = Constants?.manifest?.extra?.DEEP_LINK_PREFIX ?? "";

const createDeepLinking = () => {
  const prefix = ExpoLinking.createURL("/");
  return {
    prefixes: [DEEP_LINK_PREFIX, prefix],
  };
};

export default createDeepLinking;
