import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { OrderDeliveryStatus } from "../../../@types/appTypes";

const updateOrderDeliveryStateMutation = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) =>
  builder.mutation<
    any,
    { orderDeliveryId: string; newOrderDeliveryState: OrderDeliveryStatus }
  >({
    query: ({ orderDeliveryId, newOrderDeliveryState }) => {
      return {
        url: `_action/order_delivery/${orderDeliveryId}/state/${newOrderDeliveryState}`,
        method: "POST",
      };
    },
    invalidatesTags: ["OrderDelivery"],
  });

export default updateOrderDeliveryStateMutation;
