import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { ContainerResponseI } from "../../../@types/shopwareApiTypes";
import { ContainerI } from "../../../@types/appTypes";

const prepareContainerData = (data: ContainerResponseI): ContainerI => {
  const { id, key, state, isUsed, orderLineItemId } = data;
  return {
    id,
    key,
    state,
    isUsed,
    orderLineItemId,
  };
};

const getContainerQuery = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) =>
  builder.query<ContainerI, string>({
    providesTags: ["Container"],
    query: (containerKey) => {
      const data = {
        includes: {
          container: ["id", "key", "state", "isUsed", "orderLineItemId"],
        },
        filter: [
          {
            type: "equals",
            field: "key",
            value: containerKey,
          },
        ],
      };

      return {
        url: "search/container",
        method: "POST",
        body: data,
      };
    },
    transformResponse: ({ data }: { data: ContainerResponseI[] }) => {
      if (!data?.length) {
        return null;
      }
      return prepareContainerData(data[0]);
    }
  });

export default getContainerQuery;
