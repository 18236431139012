import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { cloneDeep } from "lodash";
import { PickwareShippingShipmentResponseI } from "../../../../@types/shopwareApiTypes";
import { ShipmentDocumentI } from "../../../../@types/appTypes";

import requestData from "./requestData.json";

const prepareShipmentDocumentData = (
  data: PickwareShippingShipmentResponseI
): ShipmentDocumentI => {
  const {
    documents: [{ id: documentId, deepLinkCode }],
    trackingCodes: [{ trackingCode }],
    orders: [order],
  } = data;
  const orderDeliveryId = order?.deliveries?.[0].id;
  const shipmentId = data.id;
  const shippingOrderAddressId = order?.deliveries?.[0].shippingOrderAddressId;
  return {
    shipmentId,
    shippingOrderAddressId,
    orderDeliveryId,
    documentId,
    deepLinkCode,
    trackingCode,
  };
};

const getShipmentDocumentQuery = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) =>
  builder.query<ShipmentDocumentI, string>({
    providesTags: ["Shipment"],
    query: (orderId) => {
      const data: any = cloneDeep(requestData);
      data.filter.push({
        type: "equals",
        field: "orders.id",
        value: orderId,
      });
      return {
        url: "search/pickware-shipping-shipment",
        method: "POST",
        body: data,
      };
    },
    transformResponse: ({
      data,
    }: {
      data: PickwareShippingShipmentResponseI[];
    }) => {
      if (!data?.length) {
        return null;
      }
      return prepareShipmentDocumentData(data[0]);
    },
  });

export default getShipmentDocumentQuery;
