import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "@services/shopware6Api/baseQuery";
import getOrderLineItemQuery from "@services/shopware6Api/endpoints/getOrderLineItemQuery";
import orderLineItemStateMutation from "@services/shopware6Api/endpoints/updateOrderLineItemStateMutation";
import updateContainerMutation from "@services/shopware6Api/endpoints/updateContainerMutation";
import {
  getReadyShippingOrderQuery,
  getShippingOrderQuery,
} from "@services/shopware6Api/endpoints/getShippingOrderQuery";
import getContainerQuery from "@services/shopware6Api/endpoints/getContainerQuery";
import getShipmentBlueprintForOrderQuery from "@services/shopware6Api/endpoints/getShipmentBlueprintForOrderQuery";
import createShipmentForOrderMutation from "@services/shopware6Api/endpoints/createShipmentForOrderMutation";
import getShipmentDocumentQuery from "@services/shopware6Api/endpoints/getShipmentDocumentQuery";
import updateOrderStateMutation from "@services/shopware6Api/endpoints/updateOrderStateMutation";
import updateOrderDeliveryStateMutation from "@services/shopware6Api/endpoints/updateOrderDeliveryStateMutation";
import updateOrderAddressMutation from "@services/shopware6Api/endpoints/updateOrderAddressMutation";
import cancelShipmentMutation from "@services/shopware6Api/endpoints/cancelShipmentMutation";

export const adminApi = createApi({
  reducerPath: "adminApi",
  // TODO kinda disables cache behavior. Using other options described here
  //  https://redux-toolkit.js.org/rtk-query/api/createApi#keepunuseddatafor
  //  might bethe better choice. It is possible to decide on endpoint basis.
  refetchOnMountOrArgChange: true,
  // keepUnusedDataFor: 1,
  baseQuery,
  tagTypes: [
    "Order",
    "OrderDelivery",
    "Shipment",
    "Container",
    "ShippingOrder",
    "OrderAddress",
    "OrderLineItem",
  ],
  endpoints: (builder) => ({
    createShipmentForOrder: createShipmentForOrderMutation(builder),
    cancelShipment: cancelShipmentMutation(builder),
    getContainer: getContainerQuery(builder),
    getOrderLineItem: getOrderLineItemQuery(builder),
    getShippingOrder: getShippingOrderQuery(builder),
    getReadyShippingOrder: getReadyShippingOrderQuery(builder),
    getShipmentBlueprintForOrder: getShipmentBlueprintForOrderQuery(builder),
    getShipmentDocument: getShipmentDocumentQuery(builder),
    updateContainer: updateContainerMutation(builder),
    updateOrderLineItemState: orderLineItemStateMutation(builder),
    updateOrderState: updateOrderStateMutation(builder),
    updateOrderDeliveryState: updateOrderDeliveryStateMutation(builder),
    updateOrderAddress: updateOrderAddressMutation(builder),
  }),
});
export const useGetOrderLineItemStateResult =
  adminApi.endpoints.getOrderLineItem.useQueryState;
export const useGetShippingOrderStateResult =
  adminApi.endpoints.getShippingOrder.useQueryState;
export const useGetContainerStateResult =
  adminApi.endpoints.getContainer.useQueryState;

export const {
  useGetOrderLineItemQuery,
  useGetShippingOrderQuery,
  useGetReadyShippingOrderQuery,
  useGetContainerQuery,
  useGetShipmentBlueprintForOrderQuery,
  useGetShipmentDocumentQuery,
  useCreateShipmentForOrderMutation,
  useCancelShipmentMutation,
  useUpdateContainerMutation,
  useUpdateOrderLineItemStateMutation,
  useUpdateOrderStateMutation,
  useUpdateOrderDeliveryStateMutation,
  useUpdateOrderAddressMutation,
} = adminApi;
