import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const updateOrderLineItemStateMutation = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) =>
  builder.mutation<
    any,
    { orderLineItemId: string; payloadState: number; data?: any }
  >({
    query: ({ orderLineItemId, payloadState, data = {} }) => {
      return {
        url: `_action/wms/${orderLineItemId}/state/${payloadState}`,
        body: data,
        method: "PATCH",
      };
    },
    invalidatesTags: ["OrderLineItem"],
  });

export default updateOrderLineItemStateMutation;
