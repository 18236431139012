import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query";
import { refreshTokenAsync, resetTokenData } from "@redux/slices/auth";
import Constants from "expo-constants";
import type { RootState } from "@redux/store";
import { resetUsername } from "@redux/slices/user";
import { setErrorMessage } from "@redux/slices/app";

const SHOP_BASE_URL = Constants?.manifest?.extra?.SHOP_BASE_URL ?? "";

const baseQuery = fetchBaseQuery({
  baseUrl: `${SHOP_BASE_URL}/api/`,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken, tokenType } = (getState() as RootState).auth.tokenData;
    if (accessToken && tokenType) {
      headers.set("Authorization", `${tokenType} ${accessToken}`);
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
    }
    return headers;
  },
});
const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  const { refreshToken } = (api.getState() as RootState).auth.tokenData;
  if (result.error && result.error.status === 401) {
    if (!refreshToken) {
      console.log("Reset user in base query. No token", result);
      api.dispatch(resetUsername());
      api.dispatch(resetTokenData());
      return result;
    }
    const refreshTokenResult = await api.dispatch(
      refreshTokenAsync(refreshToken)
    );
    const {
      refreshToken: currentRefreshToken,
    } = (api.getState() as RootState).auth.tokenData;

    console.log("Check token", {
      currentRefreshToken,
      refreshToken,
    });

    if (
      currentRefreshToken === refreshToken &&
      refreshTokenResult.type === "auth/refreshTokenAsync/rejected"
    ) {
      console.log("Reset user in base query. Token rejected");
      api.dispatch(
        setErrorMessage("Refresh token expired. Please login again.")
      );
      api.dispatch(resetTokenData());
      api.dispatch(resetUsername());
    }
  }
  return result;
};
const staggeredBaseQuery = retry(baseQueryWithAuth, {
  maxRetries: 1,
});
export default staggeredBaseQuery;
