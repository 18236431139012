import { StyleSheet, View } from "react-native";
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  withSpring,
} from "react-native-reanimated";
import { Caption, Subheading, Text } from "react-native-paper";
import { ShippingOrderLineItemI } from "../@types/appTypes";

export const CARD_HEIGHT = 200;
export const CARD_WIDTH = 300;
export const SCALE_FACTOR = -0.1;

const styles = StyleSheet.create({
  cardWrapper: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "#efefef",
    opacity: 1,
    transform: [{ translateY: 0 }, { scale: 1 }],
  },
  indicator: {
    height: 20,
    backgroundColor: "#dfdfdf",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  infoWrapper: {
    padding: 15,
    flex: 1,
    display: "flex",
  },
  text: {
    padding: 10,
    fontSize: 25,
    color: "#222",
  },
  label: {
    padding: 10,
    fontSize: 20,
    color: "#666",
  },
  stepText: {
    padding: 10,
    color: "#222",
  },
});

export interface ShippingOrderLineItemCardI {
  index: number;
  currentStepNr: Animated.SharedValue<number>;
  totalStepsNr: number;
  orderLineItem: ShippingOrderLineItemI;
}

export default function ShippingOrderLineItemCard({
  index,
  currentStepNr,
  totalStepsNr,
  orderLineItem,
}: ShippingOrderLineItemCardI) {
  const stepProgressText = `${index + 1}/${totalStepsNr}`;

  const relativeIndex = useDerivedValue(() => {
    return index - currentStepNr.value;
  }, [currentStepNr]);

  const animatedOffsetStyle = useAnimatedStyle(() => {
    const scale = SCALE_FACTOR * relativeIndex.value + 1;
    const offset =
      -CARD_HEIGHT / 2 +
      (CARD_HEIGHT / 2) * scale -
      10 * relativeIndex.value * scale;

    return {
      transform: [
        {
          translateY: withSpring(offset, {
            damping: 20,
            stiffness: 90,
          }),
        },
        {
          scale: withSpring(scale, {
            damping: 20,
            stiffness: 90,
          }),
        },
      ],
      opacity: withSpring(
        relativeIndex.value < 0 || relativeIndex.value > 3 ? 0 : 1,
        {
          damping: 20,
          stiffness: 90,
        }
      ),
    };
  }, [currentStepNr, relativeIndex]);
  return (
    <Animated.View
      style={[styles.cardWrapper, animatedOffsetStyle, { zIndex: -index }]}
    >
      <View style={styles.indicator} />
      <View style={styles.infoWrapper}>
        <Subheading style={styles.label}>{orderLineItem.label}</Subheading>
        <View style={styles.infoContainer}>
          {/* TODO https://github.com/callstack/react-native-paper/issues/1971 */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */}
          {/* @ts-ignore */}
          <Text style={styles.text}>{orderLineItem.batchNumber}</Text>
        </View>
        <Caption style={styles.stepText}>{stepProgressText}</Caption>
      </View>
    </Animated.View>
  );
}
