import { StyleSheet, View } from "react-native";
import { Appbar, Button, Dialog, Paragraph, Portal } from "react-native-paper";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { resetAppProgressState, setAppProgressState } from "@redux/slices/app";
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { getScreenNameByAppProgress } from "@util/screenInformation";
import { NavigationProp, RouteProp } from "@react-navigation/core/src/types";
import { resetBarcode } from "@redux/slices/scanner";
import { useGetOrderLineItemQuery } from "@services/shopware6Api/adminApi";
import { resetUsername } from "@redux/slices/user";
import { resetTokenData } from "@redux/slices/auth";
import {
  AppProgressState,
  ParamList,
  RootStackParamListT,
} from "../@types/appTypes";

const styles = StyleSheet.create({
  headerWrapper: {
    backgroundColor: "gray",
  },
  headerWrapperNaturopath: {
    backgroundColor: "darkgoldenrod",
  },
  dialogWrapper: {
    width: 0,
  },
});

interface HeaderActionsI {
  onActiveActionChange: Dispatch<SetStateAction<boolean>>;
}

function HeaderActions({ onActiveActionChange }: HeaderActionsI) {
  const dispatch = useAppDispatch();
  const appProgress = useAppSelector((state) => state.app.progress);
  const navigation = useNavigation<NavigationProp<RootStackParamListT>>();
  const username = useAppSelector((state) => state.user.username);
  const [isLogoutDialogVisible, setIsLogoutDialogVisible] = useState(false);
  const [isProblemDialogVisible, setIsProblemDialogVisible] = useState(false);

  return (
    <>
      <>2.2.2</>
      {appProgress !== AppProgressState.ERROR && (
        <Appbar.Action
          color="white"
          icon="alert"
          onPress={() => setIsProblemDialogVisible(true)}
        />
      )}
      {username && (
        <Appbar.Action
          color="white"
          icon="logout"
          onPress={() => setIsLogoutDialogVisible(true)}
        />
      )}
      <Appbar.Action
        color="white"
        icon="close"
        onPress={() => {
          onActiveActionChange(false);
        }}
      />
      <View style={styles.dialogWrapper}>
        <Portal>
          <Dialog
            visible={isLogoutDialogVisible}
            onDismiss={() => setIsLogoutDialogVisible(false)}
          >
            {/* TODO https://github.com/callstack/react-native-paper/issues/1971 */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */}
            {/* @ts-ignore */}
            <Dialog.Title>Wirklich Abmelden?</Dialog.Title>
            <Dialog.Content>
              <Paragraph>
                Die Applikation kann nur angemeldet genutzt werden.
              </Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setIsLogoutDialogVisible(false)}>
                ZURÜCK
              </Button>
              <Button
                onPress={() => {
                  dispatch(resetUsername());
                  dispatch(resetTokenData());
                  setIsLogoutDialogVisible(false);
                }}
              >
                OK
              </Button>
            </Dialog.Actions>
          </Dialog>
          <Dialog
            visible={isProblemDialogVisible}
            onDismiss={() => setIsProblemDialogVisible(false)}
          >
            {/* TODO https://github.com/callstack/react-native-paper/issues/1971 */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */}
            {/* @ts-ignore */}
            <Dialog.Title>Zurück zu HOME</Dialog.Title>
            <Dialog.Content>
              <Paragraph>
                Nicht abgeschlossene Bearbeitungsschritte werden zurückgesetzt!
              </Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => setIsProblemDialogVisible(false)}>
                ABBRECHEN
              </Button>
              <Button
                onPress={async () => {
                  await dispatch(resetBarcode());
                  await dispatch(resetAppProgressState());
                  setIsProblemDialogVisible(false);
                  navigation.navigate("Home");
                }}
              >
                OK
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      </View>
    </>
  );
}

export default function Header() {
  const [isHeaderActionActive, setIsHeaderActionActive] = useState(false);
  const appProgress = useAppSelector((state) => state.app.progress);
  const [headerTitle, setHeaderTitle] = useState("");

  const route = useRoute<RouteProp<ParamList>>();
  const orderLineItemId = route?.params?.orderLineItemId ?? null;
  const {
    data: orderLineItem,
    isSuccess: isOrderLineItemSuccess,
  } = useGetOrderLineItemQuery(
    {
      orderLineItemId,
    },
    {
      skip: !orderLineItemId,
    }
  );
  const isHeaderWrapperNaturopath = useMemo(
    () => (orderLineItem?.isNaturopath ? 1 : 0),
    [orderLineItem]
  );

  useFocusEffect(
    useCallback(() => {
      console.log(route);
      if (isOrderLineItemSuccess && orderLineItem?.batchNumber) {
        const { batchNumber } = orderLineItem;
        let containerKey = "";
        if (orderLineItem.container) {
          containerKey = ` - ${orderLineItem.container.key}`;
        }

        setHeaderTitle(
          `${getScreenNameByAppProgress(
            appProgress,
            route.name
          )} - ${batchNumber}${containerKey}`
        );
        return;
      }
      setHeaderTitle(getScreenNameByAppProgress(appProgress, route.name));
    }, [route, appProgress, isOrderLineItemSuccess, orderLineItem])
  );

  return (
    <>
      <Appbar.Header
        style={
          isHeaderWrapperNaturopath
            ? styles.headerWrapperNaturopath
            : styles.headerWrapper
        }
      >
        <Appbar.Content style={{}} color="white" title={headerTitle} />
        {isHeaderActionActive ? (
          <HeaderActions onActiveActionChange={setIsHeaderActionActive} />
        ) : (
          <Appbar.Action
            color="white"
            icon="dots-vertical"
            onPress={() => {
              setIsHeaderActionActive(true);
            }}
          />
        )}
      </Appbar.Header>
    </>
  );
}
