import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const updateOrderAddressMutation = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) =>
  builder.mutation<
    any,
    { orderAddressId: string; formData: Record<string, string> }
  >({
    query: ({ orderAddressId, formData }) => {
      const data = { ...formData };
      return {
        url: `order-address/${orderAddressId}`,
        method: "PATCH",
        body: data,
      };
    },
    invalidatesTags: ["OrderAddress"],
  });

export default updateOrderAddressMutation;
