import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const cancelShipmentMutation = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) =>
  builder.mutation<any, { shipmentId: string }>({
    query: (data) => {
      return {
        url: "/_action/pickware-shipping/shipment/cancel-shipment",
        method: "POST",
        body: data,
      };
    },
    invalidatesTags: ["Shipment"],
  });

export default cancelShipmentMutation;
