import { ReactElement } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { persistor, store } from "@redux/store";
import { Provider as PaperProvider } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { PersistGate } from "redux-persist/integration/react";

import AssetProvider from "@providers/AssetProvider";

const fonts = {};

interface CombinedProvidersI {
  children: ReactElement | ReactElement[];
}

const CombinedProviders = ({ children }: CombinedProvidersI) => {
  return (
    <SafeAreaProvider>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <AssetProvider {...{ fonts }}>
            <PaperProvider>{children}</PaperProvider>
          </AssetProvider>
        </PersistGate>
      </ReduxProvider>
    </SafeAreaProvider>
  );
};

export default CombinedProviders;
