import { every, map, size } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import Layout from "@components/Layout";
import { StackScreenProps } from "@react-navigation/stack";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  useGetShippingOrderQuery,
  useUpdateOrderLineItemStateMutation,
} from "@services/shopware6Api/adminApi";
import {
  clearErrorMessage,
  setAppProgressState,
  setErrorMessage,
  setInfoMessage,
} from "@redux/slices/app";

import { resetBarcode } from "@redux/slices/scanner";
import AppProgressNavigation from "@navigation/AppProgressNavigation";
import ShippingOrderLineItemCard, {
  CARD_HEIGHT,
  CARD_WIDTH,
} from "@components/ShippingOrderLineItemCard";
import Animated, { useSharedValue } from "react-native-reanimated";
import { StyleSheet } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import {
  AppProgressState,
  OrderLineItemState,
  OrderStatusActionName,
  RootStackParamListT,
} from "../@types/appTypes";

const styles = StyleSheet.create({
  cardContainer: {
    position: "relative",
    width: CARD_WIDTH,
    height: CARD_HEIGHT,
  },
});

export default function ShippingScreen({
  route,
  navigation,
}: StackScreenProps<RootStackParamListT, "Shipping">) {
  const { orderLineItemId } = route.params;
  const dispatch = useAppDispatch();
  const appProgress = useAppSelector((state) => state.app.progress);
  const barcode = useAppSelector((state) => state.scanner.barcode);

  const {
    data: order,
    isFetching,
    isSuccess: isGetShippingOrderSuccess,
  } = useGetShippingOrderQuery(orderLineItemId, {
    refetchOnFocus: true,
  });

  const [updateOrderLineItemState] = useUpdateOrderLineItemStateMutation();
  const shippingOrderLineItemEntities = useMemo(() => {
    if (isFetching) {
      return {};
    }
    return order?.orderLineItems?.entities ?? {};
  }, [order, isFetching]);
  const shippingOrderLineItemIds = useMemo(() => {
    if (isFetching) {
      return [];
    }
    return order?.orderLineItems?.ids ?? [];
  }, [order, isFetching]);
  const totalStepsNr = size(shippingOrderLineItemIds)
    ? size(shippingOrderLineItemIds)
    : 0;
  const currentStepNr = useSharedValue(0);
  const currentShippingOrderLineItemKey =
    shippingOrderLineItemIds[currentStepNr.value];
  const currentShippingOrderLineItem =
    shippingOrderLineItemEntities?.[currentShippingOrderLineItemKey];

  useEffect(() => {
    if (size(shippingOrderLineItemEntities) > 0) {
      currentStepNr.value = shippingOrderLineItemIds.reduce(
        (c: number, shippingOrderLineItemId) => {
          return shippingOrderLineItemEntities?.[shippingOrderLineItemId]
            ?.state === OrderLineItemState.SHIPPING
            ? c + 1
            : c;
        },
        0
      );
    }

    return () => {
      currentStepNr.value = 0;
    };
  }, [currentStepNr, shippingOrderLineItemEntities, shippingOrderLineItemIds]);

  useFocusEffect(
    useCallback(() => {
      console.log(
        JSON.stringify({
          appProgress,
          isGetShippingOrderSuccess,
          order,
          orderLineItemId,
          isFetching,
          barcode,
        })
      );

      if (isFetching || !isGetShippingOrderSuccess || !order) {
        return;
      }
      switch (appProgress) {
        case AppProgressState.SHIPPING_VALIDATE_ORDER_STATE: {
          const validateOrderState = async () => {
            if (!order || order.state !== OrderStatusActionName.IN_PROGRESS) {
              const errorMessage =
                "Keine gültige Bestellung gefunden. " +
                'Kontrollieren Sie ob der Bestellstatus auf "In Bearbeitung" gesetzt ist.';

              await dispatch(
                setAppProgressState({
                  progress: AppProgressState.ERROR,
                  errorMessage,
                })
              );
              return;
            }
            console.log("Check lineItems", {
              shippingOrderLineItemIds,
              shippingOrderLineItemEntities,
            });
            const readyToShipItems = shippingOrderLineItemIds.reduce(
              (c: number, shippingOrderLineItemId) => {
                const item =
                  shippingOrderLineItemEntities?.[shippingOrderLineItemId];
                const state = item?.state;

                if (item?.type !== "wunschkapsel") {
                  return c + 1;
                }

                return state === OrderLineItemState.READY ||
                  state === OrderLineItemState.SHIPPING
                  ? c + 1
                  : c;
              },
              0
            );

            if (
              shippingOrderLineItemIds.length > 0 &&
              every(shippingOrderLineItemEntities, (item) => {
                const state = item?.state;

                return (
                  item?.type !== "wunschkapsel" ||
                  state === OrderLineItemState.READY ||
                  state === OrderLineItemState.SHIPPING
                );
              })
            ) {
              dispatch(
                setAppProgressState({
                  progress: AppProgressState.SHIPPING_SCAN_ORDER_LINE_ITEMS,
                })
              );
              return;
            }
            const errorMessage =
              `Bestellung ${order.orderNumber} ist noch nicht für den Versand bereit. ` +
              `${readyToShipItems} von ${shippingOrderLineItemIds.length} Positionen wurden bearbeitet.`;

            await dispatch(
              setAppProgressState({
                progress: AppProgressState.ERROR,
                errorMessage,
              })
            );
          };
          validateOrderState();

          break;
        }
        case AppProgressState.SHIPPING_SCAN_ORDER_LINE_ITEMS: {
          const scanOrderLineItems = async () => {
            dispatch(setInfoMessage("Etikett scannen."));

            // Wenn alle Dosen im Shipping Status sind, kann das Versandlabel gedruckt werden
            if (currentStepNr.value >= totalStepsNr) {
              if (!order?.orderId) {
                dispatch(setErrorMessage("Bestelldaten sind fehlerhaft."));
                return;
              }
              await dispatch(
                setAppProgressState({
                  progress: AppProgressState.SHIPPING_CREATE_SHIPMENT_LABEL,
                })
              );
              navigation.navigate("ShippingLabelPrinter", {
                orderId: order.orderId,
                orderLineItemId,
              });
              return;
            }

            if (!barcode || !currentShippingOrderLineItem?.id) {
              return;
            }

            console.log({ barcode }, { currentShippingOrderLineItem });

            if (
              ![
                currentShippingOrderLineItem?.id,
                currentShippingOrderLineItem?.productNumber,
              ].includes(barcode)
            ) {
              dispatch(setErrorMessage("Fehler! Code erneut Scannen."));
              return;
            }
            await dispatch(clearErrorMessage());
            await updateOrderLineItemState({
              orderLineItemId: currentShippingOrderLineItem.id,
              payloadState: OrderLineItemState.SHIPPING,
            });
            dispatch(resetBarcode());
          };
          scanOrderLineItems();

          break;
        }
        default: {
          // TODO default
          // TODO find correct app progress state
          // if (
          //     orderLineItemId &&
          //     has(shippingOrderLineItemEntities, orderLineItemId)
          // ) {
          //   dispatch(updateLineItemChecked(orderLineItemId));
          //   return;
          // }
        }
      }
    }, [
      appProgress,
      barcode,
      currentShippingOrderLineItem,
      currentStepNr.value,
      dispatch,
      isFetching,
      isGetShippingOrderSuccess,
      navigation,
      order,
      orderLineItemId,
      shippingOrderLineItemEntities,
      shippingOrderLineItemIds,
      totalStepsNr,
      updateOrderLineItemState,
    ])
  );

  return (
    <AppProgressNavigation>
      <Layout hasInstructions devScreenName="shipping">
        <>
          {AppProgressState.SHIPPING_SCAN_ORDER_LINE_ITEMS && totalStepsNr > 0 && (
            <Animated.View style={styles.cardContainer}>
              {map(
                shippingOrderLineItemIds,
                (shippingOrderLineItemId, shippingOrderLineItemIdIndex) => {
                  const shippingOrderLineItem =
                    shippingOrderLineItemEntities[shippingOrderLineItemId];
                  return (
                    shippingOrderLineItem && (
                      <ShippingOrderLineItemCard
                        key={shippingOrderLineItemIdIndex}
                        index={shippingOrderLineItemIdIndex}
                        currentStepNr={currentStepNr}
                        totalStepsNr={totalStepsNr}
                        orderLineItem={shippingOrderLineItem}
                      />
                    )
                  );
                }
              )}
            </Animated.View>
          )}
        </>
      </Layout>
    </AppProgressNavigation>
  );
}
