import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

// --- Import Reducers ---
import { adminApi } from "@services/shopware6Api/adminApi";
import { printApi } from "@services/printApi/printApi";
import appReducer from "@redux/slices/app";
import authReducer from "@redux/slices/auth";
import scannerReducer from "@redux/slices/scanner";
import userReducer from "@redux/slices/user";
import persistAuthConfig from "@redux/persistAuthConfig";
// --- --------------- ---

const persistAppConfig = {
  key: "app",
  storage: AsyncStorage,
};
const persistUserConfig = {
  key: "user",
  storage: AsyncStorage,
};

// Add the imported reducers
// This is used by the setup to initiate the Redux store with the corresponding reducers
export default combineReducers({
  app: persistReducer(persistAppConfig, appReducer),
  auth: persistReducer(persistAuthConfig, authReducer),
  scanner: scannerReducer,
  user: persistReducer(persistUserConfig, userReducer),
  [adminApi.reducerPath]: adminApi.reducer,
  [printApi.reducerPath]: printApi.reducer,
});
