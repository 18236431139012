import { StyleSheet, Text, View } from "react-native";
import { Avatar } from "react-native-paper";
import { useAppSelector } from "@redux/hooks";

const styles = StyleSheet.create({
  instructionBoxContainer: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "red",
  },
  infoMessageContainer: {
    padding: 30,
    backgroundColor: "#dadada",
    borderRadius: 10,
  },
  alertMessageContainer: {
    padding: 30,
    alignItems: "center",
    flexDirection: "row",
  },
  alertMessage: {
    color: "red",
    fontSize: 14,
    flexWrap: "wrap",
  },
  alertIcon: {
    backgroundColor: "transparent",
  },
  infoMessage: {
    fontSize: 14,
    flexWrap: "wrap",
  },
});

export default function InstructionBox() {
  const infoMessage = useAppSelector((state) => state.app.infoMessage);
  const errorMessage = useAppSelector((state) => state.app.errorMessage);

  return (
    <>
      {infoMessage !== "" && (
        <View style={styles.infoMessageContainer}>
          <Text style={styles.infoMessage}>{infoMessage}</Text>
        </View>
      )}
      {errorMessage !== "" && (
        <View style={styles.alertMessageContainer}>
          {/* TODO https://github.com/callstack/react-native-paper/issues/1971 */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */}
          {/* @ts-ignore */}
          <Avatar.Icon
            color="red"
            size={35}
            icon="alert"
            style={styles.alertIcon}
          />
          <Text style={styles.alertMessage}>{errorMessage}</Text>
        </View>
      )}
    </>
  );
}
