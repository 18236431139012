import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  authenticateAsync,
  refreshAuthenticateAsync,
} from "@services/shopware6Api/authApi";
import { Loading, ValidationErrorsI } from "../../@types/appTypes";
import { TokenResponseI } from "../../@types/shopwareApiTypes";

export const signInAsync = createAsyncThunk<
  TokenResponseI,
  string,
  {
    rejectValue: ValidationErrorsI;
  }
>("auth/signInAsync", async (username, thunkAPI) => {
  try {
    const response = await authenticateAsync<TokenResponseI>(username);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const refreshTokenAsync = createAsyncThunk<
  TokenResponseI,
  string,
  {
    rejectValue: ValidationErrorsI;
  }
>("auth/refreshTokenAsync", async (refreshToken, thunkAPI) => {
  try {
    const response = await refreshAuthenticateAsync<TokenResponseI>(
      refreshToken
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.errors);
  }
});

interface TokenI {
  accessToken: string | null | undefined;
  refreshToken: string | null | undefined;
  expirationDate: number | null | undefined;
  tokenType: string | null | undefined;
}

interface AuthStateI {
  tokenData: TokenI;
  loading: Loading;
  error: string | null | undefined;
}

const initialState = {
  tokenData: {
    accessToken: null,
    refreshToken: null,
    expirationDate: null,
    tokenType: null,
  },
  loading: Loading.idle,
  error: null,
} as AuthStateI;

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetTokenData: (state) => {
      state.tokenData = initialState.tokenData;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(signInAsync.pending, refreshTokenAsync.pending),
      (state) => {
        state.loading = Loading.pending;
      }
    );
    builder.addMatcher(
      isAnyOf(signInAsync.fulfilled, refreshTokenAsync.fulfilled),
      (state, action) => {
        state.loading = Loading.succeeded;
        let timestamp = new Date().getTime();
        timestamp += action.payload.expires_in * 1000;
        state.tokenData = {
          accessToken: action.payload.access_token,
          refreshToken: action.payload.refresh_token,
          expirationDate: timestamp,
          tokenType: action.payload.token_type,
        };
      }
    );
    builder.addMatcher(
      isAnyOf(signInAsync.rejected, refreshTokenAsync.rejected),
      (state, action) => {
        state.loading = Loading.failed;
        if (action.payload) {
          state.error = action.payload.errorMessage;
        } else {
          state.error = action.error.message;
        }
      }
    );
  },
});
export const { resetTokenData } = authSlice.actions;

export default authSlice.reducer;
