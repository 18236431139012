import { StyleSheet, Text, View } from "react-native";
import { Button } from "react-native-paper";
import { StackScreenProps } from "@react-navigation/stack";
import { useAppDispatch } from "@redux/hooks";
import { resetAppProgressState, setAppProgressState } from "@redux/slices/app";
import { updateBarcode } from "@redux/slices/scanner";
import { AppProgressState, RootStackParamListT } from "../@types/appTypes";

const styles = StyleSheet.create({
  text: {
    padding: 20,
    textAlign: "center",
    color: "black",
  },
});

export const testUsername = "Jonas_1";
export const testOrderLineItemId = "83533b1762e84468988705e0a3642861";
const testContainerKey = "DOSE-0102";

export default function DevNavigationScreen({
  navigation,
}: StackScreenProps<RootStackParamListT, "DevNavigation">) {
  const dispatch = useAppDispatch();
  return (
    <View style={{ width: "100%", height: "100%" }}>
      <Text style={styles.text}>Navigation</Text>
      <Button
        onPress={() => {
          dispatch(resetAppProgressState());
          dispatch(updateBarcode(testOrderLineItemId));
          // dispatch(updateBarcode(testContainerKey));
          navigation.navigate("Home");
        }}
      >
        Home
      </Button>
      <Button
        onPress={() => {
          dispatch(setAppProgressState({ progress: AppProgressState.LOGIN }));
          dispatch(updateBarcode(testUsername));
          navigation.navigate("Login");
        }}
      >
        LogIn
      </Button>
      <Button
        onPress={() => {
          dispatch(
            setAppProgressState({
              progress: AppProgressState.INGREDIENT_SCAN_CHECK,
            })
          );
          navigation.navigate("Ingredients", {
            orderLineItemId: testOrderLineItemId,
            containerKey: testContainerKey,
          });
        }}
      >
        Ingredients
      </Button>
      <Button
        onPress={() => {
          dispatch(
            setAppProgressState({
              progress:
                AppProgressState.PRODUCT_ASSEMBLY_VALIDATE_CONTAINER_AND_ORDER_LINE_ITEM_STATE,
            })
          );
          navigation.navigate("ProductAssembly", {
            orderLineItemId: testOrderLineItemId,
            containerKey: testContainerKey,
          });
        }}
      >
        Mixing
      </Button>
      <Button
        onPress={() => {
          dispatch(
            setAppProgressState({
              progress: AppProgressState.SHIPPING_VALIDATE_ORDER_STATE,
            })
          );
          navigation.navigate("Shipping", {
            orderLineItemId: testOrderLineItemId,
          });
        }}
      >
        Shipping
      </Button>
      <Button
        onPress={() => {
          navigation.navigate("Problem");
        }}
      >
        Problem
      </Button>
    </View>
  );
}
