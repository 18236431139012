import { useCallback } from "react";
import Layout from "@components/Layout";
import { StackScreenProps } from "@react-navigation/stack";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

import { resetBarcode } from "@redux/slices/scanner";
import {
  useCreateShipmentForOrderMutation,
  useGetShipmentBlueprintForOrderQuery,
  useGetShipmentDocumentQuery,
  useUpdateOrderDeliveryStateMutation,
  useUpdateOrderStateMutation,
} from "@services/shopware6Api/adminApi";
import Constants from "expo-constants";
import {
  resetAppProgressState,
  setAppProgressState,
  setErrorMessage,
  setInfoMessage,
} from "@redux/slices/app";
import { Button } from "react-native-paper";
import { useCreatePrintJobMutation } from "@services/printApi/printApi";
import AppProgressNavigation from "@navigation/AppProgressNavigation";
import { useFocusEffect } from "@react-navigation/native";
import {
  AppProgressState,
  OrderDeliveryStatus,
  OrderStatus,
  PrinterLabelType,
  RootStackParamListT,
} from "../@types/appTypes";

const SHOP_BASE_URL = Constants?.manifest?.extra?.SHOP_BASE_URL ?? "";

export default function ShippingLabelPrinterScreen({
  route,
  navigation,
}: StackScreenProps<RootStackParamListT, "ShippingLabelPrinter">) {
  const { orderId } = route.params;
  const dispatch = useAppDispatch();
  const appProgress = useAppSelector((state) => state.app.progress);
  const barcode = useAppSelector((state) => state.scanner.barcode);
  const {
    data: shipmentDocument,
    isFetching: shipmentDocumentIsFetching,
  } = useGetShipmentDocumentQuery(orderId);
  const orderDeliveryId = shipmentDocument?.orderDeliveryId;
  const {
    data: shipmentBlueprint,
    isFetching: shipmentBlueprintIsFetching,
  } = useGetShipmentBlueprintForOrderQuery(orderId);
  const [
    createShipmentForOrder,
    { isError: isCreateShipmentForOrderError },
  ] = useCreateShipmentForOrderMutation();
  const [updateOrderState] = useUpdateOrderStateMutation();
  const [updateOrderDeliveryState] = useUpdateOrderDeliveryStateMutation();
  const [createPrintJob] = useCreatePrintJobMutation();

  const documentId = shipmentDocument?.documentId ?? "";
  const deepLinkCode = shipmentDocument?.deepLinkCode ?? "";
  const trackingCode = shipmentDocument?.trackingCode ?? "";

  useFocusEffect(
    useCallback(() => {
      if (shipmentDocumentIsFetching || shipmentBlueprintIsFetching) {
        return;
      }
      switch (appProgress) {
        case AppProgressState.SHIPPING_CREATE_SHIPMENT_LABEL: {
          const createShipmentLabel = async () => {
            if (isCreateShipmentForOrderError) {
              await dispatch(
                setAppProgressState({
                  errorMessage: "Versandetikett konnte nicht erstellt werden.",
                  progress: AppProgressState.ERROR,
                })
              );
              return;
            }
            if (!shipmentBlueprint) {
              return;
            }
            if (!orderDeliveryId) {
              console.log("no orderDeliveryId");
              await createShipmentForOrder({ orderId, shipmentBlueprint });
            }
            if (orderDeliveryId) {
              console.log("order DeliveryId is set");
            }
            dispatch(
              setAppProgressState({
                progress: AppProgressState.SHIPPING_PRINT_SHIPMENT_LABEL,
              })
            );
          };
          createShipmentLabel();

          break;
        }
        case AppProgressState.SHIPPING_PRINT_SHIPMENT_LABEL: {
          if (!!trackingCode && !!documentId && !!deepLinkCode) {
            const documentUri =
              `${SHOP_BASE_URL}/api/pickware-document/${documentId}/` +
              `contents?deepLinkCode=${deepLinkCode}`;
            createPrintJob({
              documentUri,
              labelType: PrinterLabelType.SHIPMENT,
            });

            dispatch(
              setAppProgressState({
                progress: AppProgressState.SHIPPING_SCAN_SHIPMENT_LABEL,
              })
            );
          }
          break;
        }
        case AppProgressState.SHIPPING_SCAN_SHIPMENT_LABEL: {
          dispatch(
            setInfoMessage(
              `Versandetikett mit Nummer ${trackingCode} abscannen.`
            )
          );
          if (!!barcode && !!trackingCode) {
            if (barcode !== trackingCode) {
              dispatch(
                setErrorMessage(
                  "Fehler! Sie haben das falsche Versandetikett gescanned. " +
                    `Bitte Scannen Sie das Etikett mit der Nummer ${trackingCode}.`
                )
              );
              return;
            }

            const incrementProcess = async () => {
              await dispatch(resetBarcode());
            };
            incrementProcess().then(() => {
              dispatch(
                setAppProgressState({
                  progress: AppProgressState.SHIPPING_ORDER_COMPLETED,
                })
              );
            });
          }
          break;
        }
        case AppProgressState.SHIPPING_ORDER_COMPLETED: {
          if (!orderDeliveryId) {
            return;
          }
          updateOrderState({ orderId, newOrderState: OrderStatus.COMPLETED });
          updateOrderDeliveryState({
            orderDeliveryId,
            newOrderDeliveryState: OrderDeliveryStatus.COMPLETED,
          });

          const incrementProcess = async () => {
            await dispatch(resetAppProgressState());
          };
          incrementProcess().then(() => {
            navigation.navigate("Home");
          });

          break;
        }
        default: {
          dispatch(setErrorMessage("Unbekannter Fehler!"));
        }
      }
    }, [
      appProgress,
      barcode,
      createPrintJob,
      createShipmentForOrder,
      deepLinkCode,
      dispatch,
      documentId,
      isCreateShipmentForOrderError,
      navigation,
      orderDeliveryId,
      orderId,
      shipmentBlueprint,
      shipmentBlueprintIsFetching,
      shipmentDocumentIsFetching,
      trackingCode,
      updateOrderDeliveryState,
      updateOrderState,
    ])
  );

  return (
    <AppProgressNavigation>
      <Layout hasInstructions devScreenName="shipping label printer">
        <>
          {appProgress === AppProgressState.SHIPPING_SCAN_SHIPMENT_LABEL && (
            <Button
              mode="contained"
              onPress={() => {
                if (!!documentId && !!deepLinkCode) {
                  const documentUri =
                    `${SHOP_BASE_URL}/api/pickware-document/${documentId}/` +
                    `contents?deepLinkCode=${deepLinkCode}`;
                  createPrintJob({
                    documentUri,
                    labelType: PrinterLabelType.SHIPMENT,
                  });
                }
              }}
            >
              Erneut drucken?
            </Button>
          )}
        </>
      </Layout>
    </AppProgressNavigation>
  );
}
