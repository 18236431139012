import { ReactElement } from "react";
import ErrorBoundary from "react-native-error-boundary";
import { Button, Text, View } from "react-native";
import RNRestart from "react-native-restart";

interface TopLevelErrorBoundaryI {
  children: ReactElement | ReactElement[];
}
const TopLevelFallback = ({ error }: { error: Error }) => {
  const resetError = () => {
    RNRestart.Restart();
  };
  return (
    <View>
      <Text>Error!</Text>
      <Text>{error.toString()}</Text>
      <Button onPress={() => resetError()} title="Neuladen?" />
    </View>
  );
};

const TopLevelErrorBoundary = ({ children }: TopLevelErrorBoundaryI) => {
  const errorHandler = (error: Error, stackTrace: string) => {
    /* Log the error to an error reporting service */
    console.log({ error }, { stackTrace });
  };
  return (
    <ErrorBoundary onError={errorHandler} FallbackComponent={TopLevelFallback}>
      {children}
    </ErrorBoundary>
  );
};

export default TopLevelErrorBoundary;
