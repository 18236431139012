import { useCallback, useEffect, useState } from "react";
import Layout from "@components/Layout";
import {
  adminApi,
  useGetContainerQuery,
  useGetOrderLineItemQuery,
  useGetReadyShippingOrderQuery,
  useUpdateContainerMutation,
  useUpdateOrderLineItemStateMutation,
  useUpdateOrderStateMutation,
} from "@services/shopware6Api/adminApi";
import { StackScreenProps } from "@react-navigation/stack";
import {
  isValidContainerFormat,
  isValidUuidFormat,
} from "@util/barcodeValidators";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { resetBarcode } from "@redux/slices/scanner";
import {
  clearInfoMessage,
  setAppProgressState,
  setInfoMessage,
} from "@redux/slices/app";
import AppProgressNavigation from "@navigation/AppProgressNavigation";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import { Text, View } from "react-native";
import { map } from "lodash";
import IngredientCard from "@components/IngredientCard";
import {
  AppProgressState,
  ContainerI,
  ContainerState,
  OrderLineItemI,
  OrderLineItemState,
  OrderStatus,
  OrderStatusActionName,
  RootStackParamListT,
} from "../@types/appTypes";

export default function HomeScreen({
  navigation,
}: StackScreenProps<RootStackParamListT, "Home">) {
  const username = useAppSelector((state) => state.user.username);
  const barcode = useAppSelector((state) => state.scanner.barcode);
  const appProgress = useAppSelector((state) => state.app.progress);
  const [updateContainer] = useUpdateContainerMutation();
  const [updateOrderState] = useUpdateOrderStateMutation();
  const [updateOrderLineItemState] = useUpdateOrderLineItemStateMutation();
  const dispatch = useAppDispatch();
  const [scannedContainer, setScannedContainer] = useState<ContainerI | null>(
    null
  );
  const [orderLineItemId, setOrderLineItemId] = useState<string | null>(null);
  const [
    scannedOrderLineItem,
    setScannedOrderLineItem,
  ] = useState<OrderLineItemI | null>(null);

  const isFocused = useIsFocused();

  const {
    data: container,
    isSuccess: isContainerSuccess,
    isFetching: isContainerFetching,
    refetch: refetchContainer,
  } = useGetContainerQuery(barcode, {
    skip: !isValidContainerFormat(barcode) || !isFocused,
  });

  const {
    data: orderLineItem,
    isSuccess: isOrderLineItemSuccess,
    isFetching: isOrderLineItemFetching,
    refetch: refetchOrderLineItem,
  } = useGetOrderLineItemQuery(
    {
      orderLineItemId,
    },
    {
      skip: !!scannedOrderLineItem || !isFocused,
    }
  );

  const {
    data: readyToShipOrders,
    refetch: refetchReadyToShipOrders,
  } = useGetReadyShippingOrderQuery();

  useEffect(() => {
    if (isFocused) {
      clearInfoMessage();
      refetchContainer();
      refetchOrderLineItem();
      refetchReadyToShipOrders();
    }

    return () => {
      adminApi.util.resetApiState();
      setOrderLineItemId(null);
      setScannedContainer(null);
      setScannedOrderLineItem(null);
    };
  }, [
    dispatch,
    isFocused,
    refetchContainer,
    refetchOrderLineItem,
    refetchReadyToShipOrders,
  ]);

  useFocusEffect(
    useCallback(() => {
      if (!barcode || AppProgressState.START !== appProgress) {
        return;
      }
      if (!isValidUuidFormat(barcode) && !isValidContainerFormat(barcode)) {
        dispatch(setInfoMessage(`"${barcode}" ist kein gültiger Scan.`));
        return;
      }
      if (isValidUuidFormat(barcode)) {
        setOrderLineItemId(barcode);
      }
      if (
        isContainerSuccess &&
        !isContainerFetching &&
        isValidContainerFormat(barcode)
      ) {
        console.log("Set Scanner Container", {
          container: container ?? null,
          lineItemId: container?.orderLineItemId ?? "",
        });
        setScannedContainer(container ?? null);
        setOrderLineItemId(container?.orderLineItemId ?? "");
      }
      if (
        orderLineItemId !== null &&
        !isContainerFetching &&
        !isOrderLineItemFetching &&
        isOrderLineItemSuccess
      ) {
        console.log("Set Scanner LineItem", {
          orderLineItemId,
          lineItem: orderLineItem ?? null,
        });
        setScannedOrderLineItem(orderLineItem ?? null);
      }
    }, [
      barcode,
      appProgress,
      isContainerSuccess,
      isContainerFetching,
      orderLineItemId,
      isOrderLineItemFetching,
      isOrderLineItemSuccess,
      dispatch,
      container,
      orderLineItem,
    ])
  );

  useFocusEffect(
    useCallback(() => {
      console.log(
        JSON.stringify({
          appProgress,
          scannedContainer: scannedContainer?.key,
          scannedContainerKey: scannedOrderLineItem?.container?.key,
          scannedOrderLineItemId: scannedOrderLineItem?.id,
          scannedOrderLineItem: scannedOrderLineItem?.batchNumber,
          scannedOrderState: scannedOrderLineItem?.orderState,
          scannedOrderLineItemState: scannedOrderLineItem?.state,
          barcode,
        })
      );
      if (barcode && AppProgressState.START === appProgress) {
        if (scannedContainer && scannedOrderLineItem) {
          const containerKey = barcode;
          const containerId = scannedContainer.id;
          const newOrderLineItemId = scannedOrderLineItem.id;
          const { orderId } = scannedOrderLineItem;
          const containerState = scannedContainer.state;
          switch (containerState) {
            case ContainerState.OPEN:
            case ContainerState.IN_USE: {
              const processContainer = async () => {
                if (
                  !scannedContainer.orderLineItemId &&
                  containerState !== ContainerState.IN_USE
                ) {
                  await updateContainer({
                    containerId,
                    orderLineItemId: newOrderLineItemId,
                    state: ContainerState.IN_USE,
                  });
                }
                if (scannedOrderLineItem.state === OrderLineItemState.OPEN) {
                  await updateOrderLineItemState({
                    orderLineItemId: newOrderLineItemId,
                    payloadState: OrderLineItemState.PROCESS,
                    data: {
                      user: username,
                      state: OrderLineItemState.PROCESS,
                    },
                  });
                }
                if (
                  scannedOrderLineItem.orderState !==
                  OrderStatusActionName.IN_PROGRESS
                ) {
                  await updateOrderState({
                    orderId,
                    newOrderState: OrderStatus.IN_PROGRESS,
                  });
                }

                await dispatch(resetBarcode());
                await dispatch(
                  setAppProgressState({
                    progress: AppProgressState.INGREDIENT_SCAN_CHECK,
                  })
                );
              };

              processContainer().then(() => {
                navigation.navigate("Ingredients", {
                  orderLineItemId: newOrderLineItemId,
                  containerKey,
                });
              });
              break;
            }
            case ContainerState.MIXING: {
              if (!newOrderLineItemId) {
                return;
              }
              const processMixing = async () => {
                await dispatch(resetBarcode());
                await dispatch(
                  setAppProgressState({
                    progress:
                      AppProgressState.PRODUCT_PRINT_PRODUCT_LABEL_DIALOG,
                  })
                );
              };
              processMixing().then(() => {
                navigation.navigate("ProductLabelPrinter", {
                  orderLineItemId: newOrderLineItemId,
                });
              });
              break;
            }
            case ContainerState.READY: {
              if (!newOrderLineItemId) {
                return;
              }

              dispatch(
                setAppProgressState({
                  progress: AppProgressState.ERROR,
                  infoMessage: "Bitte einen anderen Container scannen.",
                  errorMessage:
                    "Fehler! Der Container wurde bereits benutzt und muss gereinigt werden.",
                })
              );
              break;
            }
            default: {
              dispatch(
                setAppProgressState({ progress: AppProgressState.ERROR })
              );
              break;
            }
          }
        } else if (scannedOrderLineItem) {
          if (scannedOrderLineItem.id !== barcode) {
            return;
          }
          const orderLineItemState = scannedOrderLineItem?.state ?? null;
          const newOrderLineItemId = scannedOrderLineItem.id;

          console.log("Check lineItemState", orderLineItemState);

          if (orderLineItemState === null) {
            return;
          }
          switch (orderLineItemState) {
            case OrderLineItemState.MIXING: {
              const containerKey = scannedOrderLineItem?.container?.key;
              if (!containerKey) {
                break;
              }
              const processMixing = async () => {
                await dispatch(resetBarcode());
                await dispatch(
                  setAppProgressState({
                    progress:
                      AppProgressState.PRODUCT_ASSEMBLY_VALIDATE_CONTAINER_AND_ORDER_LINE_ITEM_STATE,
                  })
                );
              };

              processMixing().then(() => {
                navigation.navigate("ProductAssembly", {
                  orderLineItemId: newOrderLineItemId,
                  containerKey,
                });
              });

              break;
            }
            case OrderLineItemState.SHIPPING:
            case OrderLineItemState.READY: {
              const processReady = async () => {
                await dispatch(
                  setAppProgressState({
                    progress: AppProgressState.SHIPPING_VALIDATE_ORDER_STATE,
                  })
                );
                await dispatch(resetBarcode());
                navigation.navigate("Shipping", {
                  orderLineItemId: newOrderLineItemId,
                });
              };

              processReady();
              break;
            }
            default: {
              // Error handling
              break;
            }
          }
        }
      }
    }, [
      dispatch,
      barcode,
      scannedContainer,
      navigation,
      scannedOrderLineItem,
      updateContainer,
      updateOrderLineItemState,
      appProgress,
      updateOrderState,
    ])
  );

  return (
    <AppProgressNavigation>
      <Layout hasInstructions devScreenName="home">
        <>
          <View style={{ width: "100%", marginBottom: 10 }}>
            <Text>Nächste Position:</Text>
            {orderLineItem && (
              <Text>{`${orderLineItem.batchNumber} - ${orderLineItem.ingredients.ids.length} Inhaltsstoff(e)`}</Text>
            )}
          </View>
          {readyToShipOrders && (
            <View style={{ width: "100%" }}>
              <Text>Zum Versand bereit:</Text>
              {map(readyToShipOrders, (order, index: number) => {
                const hp = order?.isNaturopath ? "(HP)" : "";

                return (
                  order && (
                    <Text key={index}>
                      {`${order.orderNumber}${hp} - ${order.orderLineItems.ids.length} Position(en)`}
                    </Text>
                  )
                );
              })}
            </View>
          )}
        </>
      </Layout>
    </AppProgressNavigation>
  );
}
