import { StatusBar } from "expo-status-bar";
import * as Sentry from "sentry-expo";
import * as SplashScreen from "expo-splash-screen";
import NavigationContainer from "@navigation/NavigatonContainer";
import { useCallback, useEffect, useState } from "react";

import { View } from "react-native";
import Providers from "@providers/CombinedProviders";
import CombinedErrorBoundaries from "./src/errorBoundaries/CombinedErrorBoundaries";

Sentry.init({
  enableNative: false,
  // dsn:
  //   "https://337ee7c41cb045779e5467424ca2fae4@o939433.ingest.sentry.io/5889454",
  // TODO @Niklas most likely you have to change this value
  enableInExpoDevelopment: true,
  debug: false,
});

export default function App() {
  const [isAppReady, setIsAppReady] = useState(false);
  const onLayoutRootView = useCallback(async () => {
    if (isAppReady) {
      await SplashScreen.hideAsync();
    }
  }, [isAppReady]);

  useEffect(() => {
    async function prepare() {
      try {
        // Keep the splash screen visible
        await SplashScreen.preventAutoHideAsync();

        // Artificially delay for two seconds to prevent app crash. We should work on a better solution!
        // Maybe combine with app auth and a lottie animation?!
        await new Promise((resolve) => setTimeout(resolve, 2000));
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setIsAppReady(true);
      }
    }

    (async () => {
      await prepare();
    })();
  }, []);
  if (!isAppReady) {
    return null;
  }
  return (
    <Providers>
      {/* eslint-disable-next-line react/style-prop-object */}
      <StatusBar style="light" />
      <View onLayout={onLayoutRootView} style={{ flex: 1 }}>
        <CombinedErrorBoundaries>
          <NavigationContainer />
        </CombinedErrorBoundaries>
      </View>
    </Providers>
  );
}
