import { cloneDeep } from "lodash";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import Constants from "expo-constants";
import { PrinterLabelType } from "../../../../@types/appTypes";
import requestData from "./requestData.json";

const PRODUCT_LABEL_PRINTER_ID =
  Constants?.manifest?.extra?.PRODUCT_LABEL_PRINTER_ID ?? "";
const QR_CODE_LABEL_PRINTER_ID =
  Constants?.manifest?.extra?.QR_CODE_LABEL_PRINTER_ID ?? "";
const SHIPMENT_LABEL_PRINTER_ID =
  Constants?.manifest?.extra?.SHIPMENT_LABEL_PRINTER_ID ?? "";

const createPrintJobMutation = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) => {
  return builder.mutation<
    string,
    { documentUri: string; labelType: PrinterLabelType }
  >({
    query: ({ documentUri, labelType }) => {
      const data: any = cloneDeep(requestData);
      switch (labelType) {
        case PrinterLabelType.PRODUCT: {
          data.printerId = PRODUCT_LABEL_PRINTER_ID;
          data.title = `Product label: ${documentUri}`;
          data.content = documentUri;
          break;
        }
        case PrinterLabelType.QR_CODE: {
          data.printerId = QR_CODE_LABEL_PRINTER_ID;
          data.title = `QR code label: ${documentUri}`;
          data.content = documentUri;
          break;
        }
        case PrinterLabelType.SHIPMENT: {
          data.printerId = SHIPMENT_LABEL_PRINTER_ID;
          data.title = `Shipment label: ${documentUri}`;
          data.content = documentUri;
          break;
        }
        default: {
          break;
        }
      }
      return {
        url: "printjobs",
        method: "POST",
        body: data,
      };
    },
  });
};

export default createPrintJobMutation;
