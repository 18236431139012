import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { ShipmentBlueprintI } from "../../../@types/shopwareApiTypes";

const createShipmentForOrderMutation = (
  builder: EndpointBuilder<BaseQueryFn, string, string>
) =>
  builder.mutation<
    any,
    { orderId: string; shipmentBlueprint: ShipmentBlueprintI }
  >({
    query: (data) => {
      return {
        url: "/_action/pickware-shipping/shipment/create-shipment-for-order",
        method: "POST",
        body: data,
      };
    },
    invalidatesTags: ["Shipment"],
  });

export default createShipmentForOrderMutation;
