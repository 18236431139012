import { Button, Dialog, Paragraph, Portal } from "react-native-paper";
import { useState } from "react";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  button: {
    width: 300,
    backgroundColor: "#C3C3C3",
  },
});
interface DialogI {
  isDialogOpen?: boolean;
  title: string;
  text: string;
  continueText?: string;
  declineText?: string;
  cancelText?: string;
  onContinue?: any;
  onDecline?: any;
  onCancel?: any;
}
const CustomDialog = ({
  title,
  text,
  onCancel,
  cancelText = "Abbrechen",
  onDecline,
  declineText = "Nein",
  onContinue,
  continueText = "OK",
  isDialogOpen = false,
}: DialogI) => {
  const [isDialogVisible, setIsDialogVisible] = useState(isDialogOpen);

  return (
    <>
      <Portal>
        <Dialog
          visible={isDialogVisible}
          onDismiss={() => setIsDialogVisible(false)}
        >
          {/* TODO https://github.com/callstack/react-native-paper/issues/1971 */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */}
          {/* @ts-ignore */}
          <Dialog.Title>{title}</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{text}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={() => {
                onContinue();
                setIsDialogVisible(false);
              }}
            >
              {continueText}
            </Button>
            <Button
              onPress={() => {
                onDecline();
                setIsDialogVisible(false);
              }}
            >
              {declineText}
            </Button>
            <Button
              onPress={() => {
                onCancel();
                setIsDialogVisible(false);
              }}
            >
              {cancelText}
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <Button style={styles.button} onPress={() => setIsDialogVisible(true)}>
        {title}
      </Button>
    </>
  );
};
CustomDialog.defaultProps = {
  isDialogOpen: false,
  continueText: "OK",
  declineText: "Nein",
  cancelText: "Zurück",
  onContinue: () => {},
  onDecline: () => {},
  onCancel: () => {},
};

export default CustomDialog;
