import { SafeAreaView, StyleSheet, View } from "react-native";
import { Button } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import { useAppDispatch } from "@redux/hooks";
import { setAppProgressState } from "@redux/slices/app";
import { resetBarcode } from "@redux/slices/scanner";
import { NavigationProp } from "@react-navigation/core/src/types";
import Header from "./Header";
import InstructionBox from "./InstructionBox";
import Scanner from "./Scanner";
import { AppProgressState, RootStackParamListT } from "../@types/appTypes";

const styles = StyleSheet.create({
  navButton: {
    marginTop: "auto",
    backgroundColor: "#C3C3C34C",
  },
  contentContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  childrenContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    flex: 2,
    height: "100%",
  },
  instructionsContainer: {
    justifyContent: "center",
    padding: 20,
    backgroundColor: "#dfdfdf",
    flex: 1,
    height: "100%",
  },
});

interface ScannerPropsInterface {
  children?: JSX.Element;
  hasInstructions?: boolean;
  devScreenName?: string;
}

const Layout = ({
  children,
  hasInstructions = false,
  devScreenName = "-- missing information --",
}: ScannerPropsInterface) => {
  const navigation = useNavigation<NavigationProp<RootStackParamListT>>();
  const dispatch = useAppDispatch();
  const locationMessage = `Navigation | You are at: ${devScreenName}`;
  return (
    <>
      <Header />
      <Scanner />
      <SafeAreaView style={styles.contentContainer}>
        <View style={styles.childrenContainer}>{children}</View>
        {hasInstructions && (
          <View style={styles.instructionsContainer}>
            <InstructionBox />
          </View>
        )}
      </SafeAreaView>
      {__DEV__ && (
        <View
          style={{ position: "absolute", width: "100%", height: 40, bottom: 0 }}
        >
          <Button
            style={styles.navButton}
            onPress={() => {
              dispatch(resetBarcode());
              dispatch(setAppProgressState({ progress: AppProgressState.DEV }));
              navigation.navigate("DevNavigation");
            }}
          >
            {locationMessage}
          </Button>
        </View>
      )}
    </>
  );
};
Layout.defaultProps = {
  children: null,
  hasInstructions: false,
  devScreenName: "",
};

export default Layout;
