import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppProgressState } from "../../@types/appTypes";

interface AppProgressStateI {
  progress: AppProgressState;
  infoMessage: string;
  errorMessage: string;
}
interface SetAppProgressStatePayloadI {
  progress: AppProgressState;
  infoMessage?: string;
  errorMessage?: string;
}

const initialState: AppProgressStateI = {
  progress: AppProgressState.START,
  infoMessage: "",
  errorMessage: "",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setErrorAppProgressState: (state) => {
      state.progress = AppProgressState.ERROR;
    },
    resetAppProgressState: (state) => {
      state.progress = initialState.progress;
      state.infoMessage = initialState.infoMessage;
      state.errorMessage = initialState.errorMessage;
    },
    setAppProgressState: (
      state,
      action: PayloadAction<SetAppProgressStatePayloadI>
    ) => {
      const { progress, infoMessage, errorMessage } = action.payload;
      state.progress = progress;
      state.infoMessage = infoMessage ?? initialState.infoMessage;
      state.errorMessage = errorMessage ?? initialState.errorMessage;
    },
    incrementAppProgressState: (state) => {
      state.progress += 1;
      state.infoMessage = initialState.infoMessage;
      state.errorMessage = initialState.errorMessage;
    },
    decrementAppProgressState: (state) => {
      state.progress -= 1;
      state.infoMessage = initialState.infoMessage;
      state.errorMessage = initialState.errorMessage;
    },
    setInfoMessage: (state, action: PayloadAction<string>) => {
      state.infoMessage = action.payload;
    },
    clearInfoMessage: (state) => {
      state.infoMessage = initialState.infoMessage;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    clearErrorMessage: (state) => {
      state.errorMessage = initialState.errorMessage;
    },
  },
});

export const {
  setErrorAppProgressState,
  resetAppProgressState,
  setAppProgressState,
  incrementAppProgressState,
  decrementAppProgressState,
  setInfoMessage,
  clearInfoMessage,
  setErrorMessage,
  clearErrorMessage,
} = appSlice.actions;

export default appSlice.reducer;
