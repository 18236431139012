import { map, size } from "lodash";
import { useCallback, useMemo } from "react";
import { StyleSheet } from "react-native";
import IngredientCard, {
  CARD_HEIGHT,
  CARD_WIDTH,
} from "@components/IngredientCard";
import Animated, { useSharedValue } from "react-native-reanimated";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

import {
  useGetContainerQuery,
  useGetOrderLineItemQuery,
  useUpdateContainerMutation,
  useUpdateOrderLineItemStateMutation,
} from "@services/shopware6Api/adminApi";
import Layout from "@components/Layout";
import { resetBarcode } from "@redux/slices/scanner";
import {
  clearErrorMessage,
  resetAppProgressState,
  setAppProgressState,
  setErrorMessage,
  setInfoMessage,
} from "@redux/slices/app";
import { StackScreenProps } from "@react-navigation/stack";
import Constants from "expo-constants";
import { useCreatePrintJobMutation } from "@services/printApi/printApi";
import AppProgressNavigation from "@navigation/AppProgressNavigation";
import { useFocusEffect } from "@react-navigation/native";
import axios from "axios";
import {
  AppProgressState,
  ContainerState,
  OrderLineItemState,
  PrintNodeScaleResponseI,
  PrinterLabelType,
  RootStackParamListT,
} from "../@types/appTypes";

const COMPUTER_ID_CONTAINER_SCALE =
  Constants?.manifest?.extra?.COMPUTER_ID_CONTAINER_SCALE ?? "413021";
const COMPUTER_ID_VITAMINS_SCALE =
  Constants?.manifest?.extra?.COMPUTER_ID_VITAMINS_SCALE ?? "449158";
const DEVICE_CONTAINER_SCALE =
  Constants?.manifest?.extra?.DEVICE_CONTAINER_SCALE ??
  "COM3 Gram S3, display format 92";
const DEVICE_VITAMINS_SCALE =
  Constants?.manifest?.extra?.DEVICE_VITAMINS_SCALE ??
  "/dev/cu.usbserial-A10LE9GV Gram S3, display format 92";
const PRINT_API_URL = Constants?.manifest?.extra?.PRINT_API_URL ?? "";
const PRINT_API_KEY = Constants?.manifest?.extra?.PRINT_API_KEY ?? "";

const PRODUCT_LABEL_PDF_BASE_URL =
  Constants?.manifest?.extra?.PRODUCT_LABEL_PDF_BASE_URL ?? "";

const styles = StyleSheet.create({
  cardContainer: {
    position: "relative",
    width: CARD_WIDTH,
    height: CARD_HEIGHT,
  },
});

export default function IngredientsScreen({
  navigation,
  route,
}: StackScreenProps<RootStackParamListT, "Ingredients">) {
  const { orderLineItemId, containerKey } = route.params;
  const [updateOrderLineItemState] = useUpdateOrderLineItemStateMutation();
  const [updateContainer] = useUpdateContainerMutation();
  const dispatch = useAppDispatch();
  const username = useAppSelector((state) => state.user.username);
  const appProgress = useAppSelector((state) => state.app.progress);
  const barcode = useAppSelector((state) => state.scanner.barcode);

  const {
    data: orderLineItem,
    isSuccess: isOrderLineItemSuccess,
  } = useGetOrderLineItemQuery({ orderLineItemId });

  const {
    data: container,
    isSuccess: isContainerSuccess,
  } = useGetContainerQuery(containerKey);

  const [createPrintJob] = useCreatePrintJobMutation();

  const controlWeight = useMemo(
    () => (orderLineItem?.controlWeight ? orderLineItem?.controlWeight : null),
    [orderLineItem]
  );
  const controlWeightMin = useMemo(
    () =>
      orderLineItem?.controlWeight ? orderLineItem.controlWeight * 0.96 : 0,
    [orderLineItem]
  );
  const controlWeightMax = useMemo(
    () =>
      orderLineItem?.controlWeight ? orderLineItem.controlWeight * 1.04 : 0,
    [orderLineItem]
  );
  const fillerWeight = useMemo(
    () => orderLineItem?.fillerWeight?.toFixed(2) ?? 0,
    [orderLineItem]
  );
  const ingredients = useMemo(
    () => orderLineItem?.ingredients?.entities ?? {},
    [orderLineItem]
  );
  const ingredientIds = useMemo(() => orderLineItem?.ingredients?.ids ?? [], [
    orderLineItem,
  ]);
  const containerId = useMemo(() => container?.id ?? "", [container]);

  const totalStepsNr = size(ingredients) ? size(ingredients) : 0;
  const currentStepNr = useSharedValue(0);

  useFocusEffect(
    useCallback(() => {
      if (size(ingredients) > 0) {
        currentStepNr.value = Object.values(ingredients).reduce(
          (c, ingredient) =>
            ingredient?.state === OrderLineItemState.READY ? c + 1 : c,
          0
        );
      }
    }, [currentStepNr, ingredients])
  );

  useFocusEffect(
    useCallback(() => {
      const currentIngredientKey = ingredientIds[currentStepNr.value];
      const currentIngredient = ingredients?.[currentIngredientKey];
      const currentStepValue = currentStepNr.value;

      const getWeight = async (type: string) => {
        let url;
        if (__DEV__) {
          return controlWeight;
        }

        if (type === "ingredient") {
          url = `${PRINT_API_URL}/computer/${encodeURIComponent(
            COMPUTER_ID_VITAMINS_SCALE
          )}/scale/${encodeURIComponent(DEVICE_VITAMINS_SCALE)}/0`;
        } else {
          url = `${PRINT_API_URL}/computer/${encodeURIComponent(
            COMPUTER_ID_CONTAINER_SCALE
          )}/scale/${encodeURIComponent(DEVICE_CONTAINER_SCALE)}/0`;
        }

        try {
          const { data: scaleData }: PrintNodeScaleResponseI = await axios.get(
            url,
            {
              headers: { Authorization: `Basic ${PRINT_API_KEY}` },
            }
          );

          if (scaleData.ageOfData > 500 || scaleData.measurement.g < 5) {
            return null;
          }

          return scaleData.measurement.g / 1000000000;
        } catch (error) {
          dispatch(
            setErrorMessage(`Fehler! Waage nicht einsatzbereit: ${error}`)
          );
        }

        return null;
      };

      const weightCheck = async (type: string, min: number, max: number) => {
        if (__DEV__) {
          return true;
        }

        const weight = await getWeight(type);

        if (!weight) {
          return false;
        }

        return min < weight && weight < max;
      };

      console.log(
        JSON.stringify({
          isTriggered: !isContainerSuccess || !isOrderLineItemSuccess,
          barcode,
          currentIngredientKey,
          totalStepsNr,
          step: currentStepNr.value,
        })
      );

      if (!isContainerSuccess || !isOrderLineItemSuccess) {
        return;
      }

      if (
        appProgress < AppProgressState.INGREDIENT_SCAN_CONTROL_WEIGHING &&
        currentStepValue >= totalStepsNr
      ) {
        dispatch(
          setAppProgressState({
            progress: AppProgressState.INGREDIENT_SCAN_CONTROL_WEIGHING,
          })
        );
        return;
      }

      const lineItemId = orderLineItemId;
      switch (appProgress) {
        case AppProgressState.INGREDIENT_SCAN_CHECK: {
          const scanCheck = async () => {
            if (currentIngredient?.needsWeighing) {
              dispatch(
                setInfoMessage(
                  "Dose auf Waage platzieren und Code innen scannen."
                )
              );
            } else {
              dispatch(setInfoMessage("Code innen scannen."));
            }

            if (!barcode) {
              return;
            }

            if (barcode !== currentIngredient?.productNumber) {
              dispatch(setErrorMessage("Fehler! Erneut Scannen. [IN]"));
              return;
            }
            if (currentIngredient?.needsWeighing) {
              console.log("Current Ingredient needs weighing");
              const beforeWeight = await getWeight("ingredient");
              if (!beforeWeight) {
                await dispatch(resetBarcode());
                dispatch(
                  setInfoMessage(
                    "Bitte Dose auf Waage platzieren und Code innen noch einmal scannen."
                  )
                );
                return;
              }
            }

            await dispatch(resetBarcode());
            dispatch(
              setAppProgressState({
                progress: AppProgressState.INGREDIENT_SCAN_CLOSE,
              })
            );
          };
          scanCheck();
          break;
        }
        case AppProgressState.INGREDIENT_SCAN_CLOSE: {
          const scanClose = async () => {
            dispatch(
              setInfoMessage("Passende Menge abwiegen und Code außen scannen.")
            );

            if (!barcode) {
              return;
            }
            dispatch(clearErrorMessage());

            if (
              !["A1", "B2", "C3", "D4", "E5"].includes(barcode.toUpperCase())
            ) {
              dispatch(setErrorMessage("Fehler! Erneut Scannen. [OUT]"));
              return;
            }

            const currentIngredientId = currentIngredient?.id;
            const currentIngredientNeedsWeighing =
              currentIngredient?.needsWeighing;
            const currentIngredientPayload = currentIngredient?.payload;

            if (!currentIngredientId || !currentIngredientPayload) {
              await dispatch(
                setAppProgressState({
                  progress: AppProgressState.ERROR,
                  errorMessage: "Es ist ein Fehler aufgetreten.",
                })
              );
              return;
            }

            if (false && currentIngredientNeedsWeighing) {
              const weightCheckSuccessful = await weightCheck(
                "ingredient",
                controlWeightMin,
                controlWeightMax
              );

              if (!weightCheckSuccessful) {
                dispatch(resetBarcode());
                dispatch(setErrorMessage("Fehler! Gewicht nicht korrekt"));
                return;
              }
            }

            await dispatch(resetBarcode());

            await updateOrderLineItemState({
              orderLineItemId: currentIngredientId,
              payloadState: OrderLineItemState.READY,
              data: {
                user: username,
                state: OrderLineItemState.READY,
                ingredient: currentIngredient?.productNumber,
              },
            });

            currentStepNr.value = currentStepValue + 1;

            dispatch(
              setAppProgressState({
                progress: AppProgressState.INGREDIENT_SCAN_CHECK,
              })
            );
          };
          scanClose();
          break;
        }
        case AppProgressState.INGREDIENT_SCAN_CONTROL_WEIGHING: {
          if (controlWeight === null) {
            const hasProblem = async () => {
              await dispatch(
                setAppProgressState({
                  errorMessage: "Bestellung hat kein Kontrollgewicht.",
                  progress: AppProgressState.ERROR,
                })
              );
            };
            hasProblem();

            return;
          }
          const scanControlWeighing = async () => {
            dispatch(
              setInfoMessage(
                `Bitte ${fillerWeight}g Füllstoff hinzufügen` +
                  " und anschließend das Gewicht abgleichen."
              )
            );

            if (!barcode) {
              return;
            }

            if (barcode.toUpperCase() !== "WEIGHTONE") {
              dispatch(setErrorMessage("Fehler! Erneut Scannen. [WEIGHTONE]"));
              return;
            }

            const correctWeight = await weightCheck(
              "final",
              controlWeightMin,
              controlWeightMax
            );
            console.log("Check weight", {
              correctWeight,
              controlWeightMin,
              controlWeightMax,
            });

            await dispatch(resetBarcode());
            if (correctWeight) {
              await updateOrderLineItemState({
                orderLineItemId: lineItemId,
                payloadState: OrderLineItemState.PROCESS,
                data: {
                  user: username,
                  state: OrderLineItemState.PROCESS,
                  weight: await getWeight("final"),
                },
              });
              dispatch(
                setAppProgressState({
                  progress: AppProgressState.INGREDIENT_SCAN_MIXING_HATCH,
                })
              );
            } else {
              dispatch(setErrorMessage("Fehler! Gewicht nicht korrekt"));
            }
          };

          scanControlWeighing();
          break;
        }
        case AppProgressState.INGREDIENT_SCAN_MIXING_HATCH: {
          const finishProcess = async () => {
            dispatch(
              setInfoMessage(
                "Bitte fertige Dose zur Mischstation bringen und Code scannen."
              )
            );

            if (!barcode) {
              return;
            }

            if (barcode.toUpperCase() !== "ACTIONMIX") {
              dispatch(setErrorMessage("Fehler! Erneut Scannen. [ACTIONMIX]"));
              return;
            }
            if (!orderLineItemId || !orderLineItem?.payload || !containerId) {
              await dispatch(
                setAppProgressState({
                  errorMessage: "Es ist ein Fehler aufgetreten.",
                  progress: AppProgressState.ERROR,
                })
              );
              return;
            }
            await dispatch(resetBarcode());
            await dispatch(resetAppProgressState());

            await updateContainer({
              containerId,
              orderLineItemId: lineItemId,
              state: ContainerState.MIXING,
            });
            await updateOrderLineItemState({
              orderLineItemId: lineItemId,
              payloadState: OrderLineItemState.MIXING,
              data: {
                user: username,
                state: OrderLineItemState.MIXING,
              },
            });

            const documentUri = `${PRODUCT_LABEL_PDF_BASE_URL}${lineItemId}`;
            createPrintJob({
              documentUri,
              labelType: PrinterLabelType.PRODUCT,
            });
          };
          finishProcess();
          break;
        }
        default: {
          dispatch(setInfoMessage(""));
        }
      }
    }, [
      ingredientIds,
      currentStepNr,
      ingredients,
      isContainerSuccess,
      isOrderLineItemSuccess,
      barcode,
      orderLineItemId,
      appProgress,
      dispatch,
      totalStepsNr,
      updateOrderLineItemState,
      username,
      controlWeightMin,
      controlWeightMax,
      controlWeight,
      fillerWeight,
      orderLineItem?.payload,
      containerId,
      updateContainer,
      createPrintJob,
    ])
  );

  return (
    <AppProgressNavigation>
      <Layout hasInstructions devScreenName="ingredients">
        <>
          {totalStepsNr > 0 && (
            <Animated.View style={styles.cardContainer}>
              {map(
                Object.values(ingredients),
                (ingredient, index: number) =>
                  ingredient && (
                    <IngredientCard
                      index={index}
                      key={index}
                      totalStepsNr={totalStepsNr}
                      currentStepNr={currentStepNr}
                      ingredient={ingredient}
                    />
                  )
              )}
            </Animated.View>
          )}
        </>
      </Layout>
    </AppProgressNavigation>
  );
}
